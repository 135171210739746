<template>
  <div class="table-container">
    <table v-if="!isLoading" class="table table-striped custom-table">
      <thead>
        <tr>
          <th v-for="column in columns" :key="column.field">{{ column.label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rows" :key="row.id" @click="handleRowClick(row.deliveryNoteIds, row.id)" class="clickable-row">
          <td v-for="column in columns" :key="column.field">{{ row[column.field] }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="isLoading">Loading...</div>
    <div class="pagination">
      <label for="pageSize">Rows per page:</label>
      <select id="pageSize" v-model="remoteParams.pageSize" @change="updatePageSize">
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <button @click="changePage('prev')" :disabled="remoteParams.page === 1">Previous</button>
      <span>Page {{ remoteParams.page }} of {{ totalPages }}</span>
      <button @click="changePage('next')" :disabled="remoteParams.page === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toast-notification";
const $toast = useToast();
import axios from "axios";
import { useDeliveryNotePrintedStore } from '@/stores/deliveryNotePrinted.js';

export const apiInstance = axios.create({
  responseType: "json",
  headers: {
    // you dont need to pre-define all headers
  },
});

const token =
"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiMSIsInJvbGUiOiJVc2VyIiwibmJmIjoxNjk0MDg2ODM1LCJleHAiOjE2OTQ2OTE2MzUsImlhdCI6MTY5NDA4NjgzNSwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NzE2NCIsImF1ZCI6Imh0dHBzOi8vbG9jYWxob3N0OjcxNjQifQ.buxKyk5Tv_o5J3syVWZuziFJ46ZXCxnp2G6XV9yPJ3g";
apiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default {
  data() {
    return {
      ids: [],
      remoteParams: {
        page: 1,
        pageSize: 10, // Default number of rows displayed
        sortBy: "Id",
        sortDirection: "desc",
        searchQuery: ""
      },
      hideAbTable: false,
      pageTitle: "Past Delivery Notes",
      isLoading: false,
      columns: [
        {label: "Id", field: "id",type: "number",width: "5%", sortable: false},
        { label: "Bag Number", field: "bagNumbers", type: "number", width: "5%" },
        //{ label: "delivery Note Ids", field: "deliveryNoteIds", type: "number", width: "5%"},
        //{ label: "Delivery Note Id", field: "deliveryNoteId" },
        //{ label: "Inspection Type", field: "inspectionType" },
        //{ label: "Quality", field: "quality" },
        //{ label: "Assigned To", field: "assignedTo" },
        //{ label: "Bx2 Assigned To", field: "bx2AssignedTo" },
        //{ label: "TSS Code", field: "tssCode" },
        //{ label: "Date Of Issue", field: "dateOfIssue" },
      ],
      rows: [],
      totalCount: 0
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.remoteParams.pageSize);
    }
  },
  mounted() {
    this.fetchRemoteData(this.remoteParams);
  },
  methods: {
    async fetchRemoteData(remoteParams) {
      this.isLoading = true;
      const DeliveryNotePrintedStore = useDeliveryNotePrintedStore();
      const response = await DeliveryNotePrintedStore.searchDeliveryNotePrinted(this.remoteParams.page,
          this.remoteParams.pageSize,
          this.remoteParams.sortBy,
          this.remoteParams.sortDirection,
          this.remoteParams.searchQuery
        );
      this.rows = response.items;
      this.totalCount = response.totalCount;
      this.isLoading = false;
    },
     async deliverNo() {
      console.log("selectedRows", this.selectedRows);
      const ids = this.selectedRows.map(row => row.id);
      const bagNumbers = this.selectedRows.map(row => row.bagNumber);
      const deliveryNoteStore = useDeliveryNotePrintedStore();
      const response = await deliveryNoteStore.createDeliveryNotePrinted(ids, bagNumbers);
      if (response.ok) {
        console.log("IDs successfully sent to the new API.");
        this.$router.push({ name: 'toDeliver', params: { ids: ids.join(',') } });
      }
    },
    handleRowClick(deliveryNoteIds, id) {
      localStorage.setItem("selectedRowId", id);
      var deliverynoteids = JSON.stringify(deliveryNoteIds);
      var result = JSON.parse(deliverynoteids).join(',');

  console.log('delivery', result);

  const deliveryNotes = this.rows
    .filter(row => deliveryNoteIds.includes(row.deliveryNoteIds))
    .map(row => row.bagNumbers)
    .flat();

  // Store both deliveryNoteIds and deliveryNotes in localStorage
  localStorage.setItem("deliveryNoteIds", result);

  // Navigate to the PastDeliveryDetails page
  this.goToDetails('PastDeliveryDetails',result );
},

goToDetails(path, deliveryNoteIds) {
  console.log('Navigating to details page:', `/${path}/${deliveryNoteIds}`);
  this.$router.push({ path: `/${path}/${deliveryNoteIds}` });
},
    updatePageSize() {
      this.remoteParams.page = 1; // Reset to the first page
      this.fetchRemoteData(this.remoteParams);
    },
    changePage(direction) {
      if (direction === 'prev' && this.remoteParams.page > 1) {
        this.remoteParams.page--;
      } else if (direction === 'next' && this.remoteParams.page < this.totalPages) {
        this.remoteParams.page++;
      }
      this.fetchRemoteData(this.remoteParams);
    }
  },
};
</script>

<style>
@import url("https://unpkg.com/css.gg@2.0.0/icons/css/trash.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}

.custom-table {
  background-color: white;
  border-collapse: collapse;
  width: 100%;
  max-width: 80%;
  margin: 2rem 0;
  border: 1px solid #ccc;
}

.custom-table th, .custom-table td {
  padding: 1rem;
  text-align: center;
  border: 1px solid #ccc;
  background-color: white; /* Ensure all cells have a white background */
}

.clickable-row {
  cursor: pointer;
}

.clickable-row:hover {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.pagination select, .pagination button {
  margin: 0 0.5rem;
}

.btn-report {
  background: #008cba;
  color: white;
  text-align: center;
  border-radius: 500px;
  margin: 10px auto;
}

.btn-succes {
  background: #008cba;
  color: white;
  text-align: center;
  border-radius: 500px;
  margin: 10px auto;
}
</style>
