<template>
  <ABTable class="px-6" ref="goodsTable" :isLoading="isLoading" @onFetchRemoteData="fetchRemoteData"
      :table-data="rows" :page-title="pageTitle" :router-url="routerUrl" :filters="filters"
      :columns="filteredColumns" :select-options="{enabled: false}" @on-select-all="selectAll"
      :total-count="totalCount" :hasDetailsPage="true" @onDeleteRow="deleteRow"
      :showSelectionColumn="true">
      <template v-slot:createButton>
        <button @click="toggleColumns()" id="extraColbtn" type="button" class="my-3 mx-1">
          {{ showAdvancedColumns ? 'Hide Extra Columns' : 'Show More Columns' }}
        </button>
      </template>  
      <template v-slot:actions="{row}">
        <div class="flex items-center justify-center w-full h-full">
          <button @click="showAlert(row.id)" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
            <i class="gg-trash"></i>
          </button>
          <button @click="modalShowned = true; selectedRow = row" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300" >
             <i class="bi bi-pencil"></i>
          </button>
        </div>
      </template>
      <template v-slot:tableRow="{ row, column}">
        <template v-if="column.field === 'assignedTo'">
          <div class="input-select-container">
            <input type="text" v-model="row.searchQueryAssignedTo" placeholder="Search Employee" class="input-field" :disabled="row.quantityPassed > 0"/>
            <select class="select-field" v-model="row.assignedTo" @change="assignBag(row.id, row.assignedTo)" :disabled="row.quantityPassed > 0">
              <option default :value="null">Select Employee</option>
              <option v-for="user in filteredUsers(row.searchQueryAssignedTo)" :key="user.id" :value="user.id">
                {{ user.name }} {{ user.surname }} | {{ user.punchCode }}
              </option>
            </select>
          </div>
        </template>
        <template v-if="column.field === 'bx2AssignedTo'">
          <div class="input-select-container">
            <input v-if="isBx2(row)" type="text" v-model="row.searchQueryBx2AssignedTo" placeholder="Search Employee" class="input-field" :disabled="row.quantityPassed > 0"/>
            <select v-if="isBx2(row)" class="select-field" v-model="row.bx2AssignedTo" @change="assignBagBx2(row)" :disabled="row.quantityPassed > 0">
              <option default :value="null">Select Employee</option>
              <option v-for="user in filteredUsers(row.searchQueryBx2AssignedTo)" :key="user.id" :value="user.id">
                {{ user.name }} {{ user.surname }} | {{ user.punchCode }}
              </option>
            </select>
          </div>
        </template>
        <template v-if="column.field === 'assignedDate'">
              {{ row.assignedDate != null ? format(parse(row.assignedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS", new Date()), 'dd/MM/yyyy') : ' ' }}
            </template>
            <template v-if="column.field === 'return'">
              <button @click="showRecievedModal(row)" :disabled="row.assigned == false || row.quantityIssued == 0 || row.quantityPassed > 0" class="btn btn-primary">Received</button>
            </template>
      </template>
              
     
  </ABTable>

  <!--Edit Modal-->
  <div v-if="modalShowned" class="modal d-block mt-5" id="editBag" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modalShowned = false"></button>
          </div>
          <div class="modal-body">
            <FormComponent :showSubmitButton="false" ref="editform" :inputValues="selectedRow" v-model="selectedRow">
              <template v-slot:formInput="{formData, inputName}">
                <div v-if="inputName == 'deliveryNoteId'"></div>
                <div v-if="inputName == 'multiplePerson'" class="mb-5 px-1 w-100 d-flex">
                <div v-if="inputName == 'isReturned'" class="mb-5 px-1 w-100 d-flex"></div>
                  
                </div>
              </template>
            </FormComponent>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" data-bs-dismiss="modal" class="btn btn-primary" @click="EditBag(selectedRow, true)">
             Update
            </button>
          </div>
        </div>
      </div>
  </div>

  <!---Received modal-->
  <div v-if="modalReceivedShowned"  class="modal d-block mt-5" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Check Bag</h1>
          <button type="button" class="btn-close" @click="modalReceivedShowned = false" aria-label="Close"></button>
        </div>
        <form @submit.prevent="EditBag(selectedRow)">
        <div class="modal-body">
            <div class="form-group">
              <div class="rows">
                <div class="col-6">
                    <label for="quantityIssued" style="font-weight: bold; font-size: 18px">Quantity issued: </label>
                    <span style="font-weight: bold; font-size: 18px">
                      {{ selectedRow.quantityIssued }}
                    </span>
                </div>
                <br/><br/>
              </div>
              <div class="row">
                <div class="col-6">
                  <label for="quantityPassed">Quantity Passed</label>
                  <input type="number" class="form-control" id="quantityPassed" v-model="selectedRow.quantityPassed" placeholder="Quantity Passed"/>
                </div>
                <div class="col-6">
                  <label for="quantityFailed">Quantity Failed</label>
                  <input type="number" class="form-control" v-model="selectedRow.quantityFailed" id="quantityFailed"
                      placeholder="Quantity Failed"/>
                </div>
              </div>
              <v-textarea class="mt-5" label="Notes" variant="solo"  v-model="selectedRow.notes"></v-textarea>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="submit" class="btn btn-primary" :disabled="!isConfirmedAllowed(selectedRow)">
            <template v-if="!isConfirmedAllowed(selectedRow)">
              Quantity Passed and Failed should be equal to Quantity Issued
            </template>
            <template v-else>
              Confirm
            </template>
          </button>
        </div>
      </form>
      </div>
    </div>
  </div>
</template>

<script>

import ABTable from '../components/ABTable.vue'
import { useGoodsStore } from '@/stores/goods.js'
import { useToast } from "vue-toast-notification";
import FormComponent from '@/components/FormComponent.vue'
import { useBagInventoryStore } from '@/stores/bagInventory.js'
import { useEmployeeStore } from '@/stores/employees.js'
import { parse, format } from 'date-fns';
const $toast = useToast();

export default {
    name: 'Issued',
    components: {
        ABTable,
        FormComponent
    },
    data() {
        return {
            format,
            parse,
            showAdvancedColumns: false,
            remoteParams:{},
            modalShowned: false,
            modalReceivedShowned: false,
            isLoading: false,
            filters:{},
            rows: [],
            selectedRow: {},
            searchQuery: "",
            page: 1,
            pageSize: 3,
            pageSizes: [3, 10, 20, 100],
            totalCount: 0,
            sortBy: "Id",
            sortDirection: "asc",
            pageTitle: 'Issued',
            columns: [
              {label: "Id", field: "id",type: "number",width: "5%", sortable: false},
              {label: "Bag_Number",field: "bagNumber", sortable: false},
              {label: "MO Number", field: "moNumber", sortable: false },
              {label: "Chit Number", field: "chitNumber", sortable: false},
              {label: "Part Number",field: "partNumber", sortable: false},
              {label: "Supplier", field: "supplier", sortable: false, },
              {label: "Inspection Type",field: "inspectionType", sortable: false},
              {label: "Quality", field: "quality", sortable: false},
              {label: "Quantity Issued",field: "quantityIssued",type: "number", sortable: false},
              {label: "Date Issued",field: "dateIssued",type: "string", sortable: false },
              {label: "Requested Date", field: "requestedDate", inputFormat: "DD/MM/YYYY",outputFormat: "DD/MM/YYYY", sortable: false},
              {label: "Assigned To",field: "assignedTo", sortable: false},
              {label: "Second Assigned To",field: "bx2AssignedTo", sortable: false},
              {label: "Assigned Date", field: "assignedDate", inputFormat: "DD/MM/YYYY", outputFormat: "DD/MM/YYYY", sortable: false},
              {label: "", field: "return", sortable: false},
            ],
            codes: [],
            users: [],
        }
    },
   created() {
      this.fetchEmployee()
    },
    computed: {
      filteredColumns() {
        if (this.showAdvancedColumns) {
            return this.columns; // Show all columns when true
        } else {
            // Hide specific columns when false
            return this.columns.filter(column => !['id', 'moNumber', 'chitNumber','supplier'].includes(column.field));
        }
      }
    },
    methods: {
      filteredUsers(query) {
      if (!query) return this.users;
      return this.users.filter(user => {
        const fullName = `${user.name || ''} ${user.surname || ''}`.toLowerCase();
        const punchCode = (user.punchCode || '').toLowerCase();
        return fullName.includes(query.toLowerCase()) || punchCode.includes(query.toLowerCase());
      });
    },
      toggleColumns() {
        this.showAdvancedColumns = !this.showAdvancedColumns;  // Toggle the state
    },
      selectAll(data){
        console.log("selectAll",data)
      },
      showRecievedModal(row){
        this.modalReceivedShowned = true
        this.selectedRow = row
        if(!this.selectedRow.quantityPassed){
          this.selectedRow.quantityPassed = 0
        }
        
        if(!this.selectedRow.quantityFailed){
          this.selectedRow.quantityFailed = 0
        }
      },
      isConfirmedAllowed(row){
        console.log(isNaN(row.quantityPassed) || isNaN(row.quantityFailed))
        if(isNaN(row.quantityPassed) && isNaN(row.quantityFailed)) return false;
        if((Number(row.quantityPassed) + Number(row.quantityFailed)) != Number(row.quantityIssued)) return false;
        return true;
      },

      isBx2(row){
        console.log(row.multiplePerson)
        if(row.multiplePerson === true) return true;
      },
      async fetchRemoteData(remoteParams) {
        this.remoteParams = remoteParams
        this.isLoading = true
        const goodsStore = useGoodsStore()
        const dataResponse = await goodsStore
          .searchGoods(remoteParams.page, remoteParams.pageSize, remoteParams.sortBy, remoteParams.sortDirection, remoteParams.searchQuery)
        this.rows = await dataResponse.items
        this.rows = this.rows.map((row) => {
          // row.assigned = true
          return row
        })
        this.totalCount = await dataResponse.totalCount
        this.isLoading = false
      },

        async deleteRow(id){
            const goodStore = useGoodsStore()
            await goodStore.deleteGood(id)
              .then((result) => {
                if (result.ok) {
                  const findIndex = this.rows.findIndex((row) => row.id === id);
                  if(findIndex > -1){
                    this.rows.splice(findIndex, 1)
                    this.totalCount--;
                    $toast.success(`Bag Number with id (${id}) deleted`);
                  }
                  //this.refreshData();
                } 
                else $toast.error(`Error deleting data ${id}`);
              });
            
        },
        showAlert(id) {
          this.$swal
            .fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              showCancelButton: true,
              cancelButtonColor: "#d33",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Yes, delete it!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.deleteRow(id);
              }
            });
        },

        async EditBag(row, isEdit = false){
          console.log(row)
          const goodStore = useGoodsStore()
          await goodStore.updateGoods(row, isEdit ? row.id : null)
            .then(async (result) => {
            if (result.status >= 200 && result.status <= 205) {
              $toast.success(`Bag ${row.bagNumber} Edited`);
              if(isEdit){
                this.modalShowned = false
              }
              else{
                this.modalReceivedShowned = false
              }
              this.$refs.goodsTable.reload()
            } else{
              let error = await result.text();
              console.log(error)
              $toast.error("Error editing bag. Error: " + error)
            }
          })
          .catch((err) => $toast.error("Error editing bag") + err);
        },

        async fetchEmployee(){
          const employeeStore = useEmployeeStore()
          await employeeStore
            .fetchEmployees()
            .then(async (res) => {
              if (res.ok) {
                this.users = await res.json();
                this.users.sort((a, b) => a.name.localeCompare(b.name));
              } else $toast.error("Error fetching Employees");
            })
            .catch((err) => {
              $toast.error("Error fetching Employees") + err;
            });
          },

        async assignBag(bagInventoryId, employeeId) {
          console.log(typeof bagInventoryId + " " + Number(employeeId));
          const BagInventoryStore = useBagInventoryStore()
          await BagInventoryStore
          .assignEmployeeBagInventory({bagInventoryId: bagInventoryId, employeeId:Number(employeeId)})
            .then((result) => {
              if (result.status >= 200 && result.status <= 205) {
                this.fetchRemoteData(this.remoteParams)
                $toast.success(` ${bagInventoryId} assigned to  ${employeeId}`);
              }
            })
            .catch((err) => $toast.error("Error assigning bag") + err);
          },

          async assignBagBx2(row) {
              console.log(typeof row.id + " " + Number(row.bx2AssignedTo));
              const BagInventoryStore = useBagInventoryStore()
              await BagInventoryStore
              .assignBx2EmployeeBagInventory({bagInventoryId: row.id, employeeId:Number(row.bx2AssignedTo)})
                .then((result) => {
                  if (result.status >= 200 && result.status <= 205) {
                    this.fetchRemoteData(this.remoteParams)
                    $toast.success(` ${row.id} assigned to  ${row.bx2AssignedTo}`);
                  }
                })
                .catch((err) => $toast.error("Error assigning bag") + err);
          },
    },
}
</script>

<style scoped>
@import url("https://unpkg.com/css.gg@2.0.0/icons/css/trash.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

.table-container {
    max-width: 95%; /* Adjust this value as needed for your layout */
    margin: auto; /* This centers the div */
    padding: 20px; /* Optional: Adds some spacing inside the div */
    box-shadow: 0px 0px 5px #ccc; /* Optional: Adds a subtle shadow for better aesthetics */
}

#extraColbtn {
    float: left;
    background-color: hsl(83, 70%, 41%);
    color: white;
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.input-select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-field,
.select-field {
    width: 150px;
    margin-bottom: 5px;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-field {
    margin-bottom: 0;
}

.select-field {
    margin-top: 0;
}
</style>