<template>
    <ABTable @onFetchRemoteData="fetchRemoteData" :table-data="auditLogs" :page-title="pageTitle" :router-url="routerUrl"
        :columns="columns" :total-count="totalCount" :hasDetailsPage="true" :noActions="true" :showCreateButton="false">
            <template v-slot:tableRow="{ row, column }">
                <template v-if="column.field === 'logDate'">
                    {{ format(parse(row.logDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS", new Date()), 'dd/MM/yyyy HH:mm:ss') }}
                </template>
                <template v-if="column.field === 'logData'">
                    <pre class="text-left max-h-40 overflow-y-auto break-all" v-html="syntaxHighlight(JSON.stringify(JSON.parse(row.logData), null, 4))"></pre>
                </template>
            </template>
            <template v-slot:cardRow="{ row, column }">
                <template v-if="column.field === 'logDate'">
                    {{ format(parse(row.logDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS", new Date()), 'dd/MM/yyyy HH:mm:ss') }}
                </template>
                <template v-if="column.field === 'logData'">
                    <pre class="text-left max-h-40 overflow-y-auto break-all" v-html="syntaxHighlight(JSON.stringify(JSON.parse(row.logData), null, 4))"></pre>
                </template>
            </template>
        </ABTable>
</template>
<style>
table+div>*,
table+div>span>select {
    display: inline-block;
}

table+div>span {
    float: right;
}
</style>
  
<script>
import ABTable from '../../components/ABTable.vue'
import { useAuditStore } from '@/stores/audit'
import { parse, format } from 'date-fns';
export default {
    name: 'AuditList',
    components: {
        ABTable
    },
    data() {
        return {
            parse, 
            format,
            auditLogs: [],
            searchQuery: "",
            page: 1,
            pageSize: 3,
            pageSizes: [3, 10, 20, 100],
            totalCount: 0,
            sortBy: "Id",
            sortDirection: "desc",
            pageTitle: 'Audit Logs',
            routerUrl: 'pipeline',
            columns: [
                { label:"ID", field: "id", visible: true },
                { label:"Table", field: "tableName", visible: true },
                { label:"Action",  field: "logType", visible: true },
                { label:"Data",  field: "logData", visible: true },
                { label:"Date",  field: "logDate", visible: true },
            ]

        }
    },
    methods: {
        replaceValue(obj, keyToFind, newValue) {
            // Check if the input object is an object
            if (typeof obj === 'object' && obj !== null) {
                // Iterate through all keys in the object
                for (const key in obj) {
                    // Check if the current key is equal to the key to find
                    if (key === keyToFind) {
                        // Replace the value with the new value
                        obj[key] = newValue;
                    } else {
                        // If the current key is not the key to find, recursively search in nested objects
                        this.replaceValue(obj[key], keyToFind, newValue);
                    }
                }
            }
        },
        syntaxHighlight(json) {
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+]?\d+)?)/g, function (match) {
                var cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="' + cls + '">' + match + '</span>';
            });
        },
        async fetchRemoteData(remoteParams) {
            const auditStore = useAuditStore()
            const dataResponse = await auditStore
                .searchLogs(remoteParams.page, remoteParams.pageSize, remoteParams.sortBy, remoteParams.sortDirection, remoteParams.searchQuery)
            this.auditLogs = await dataResponse.items
            .map( (item) => {
                var parseData = JSON.parse(item.logData)
                this.replaceValue(parseData, 'BridgePassword', '********')
                console.log(parseData)
                item.logData = JSON.stringify(parseData)
                return item
            })
            this.totalCount = await dataResponse.totalCount
        },
    },
}
</script>
<style>
pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }

</style>
<!-- 
    Pagination
    Search
    Sort
    Configure Display Columns
    Save display columns / sorting
    dynamically specify store
    fully generalize
 -->
<!-- //Note for Later
Request display columns from the db,
and provide a UI to manage it
-->