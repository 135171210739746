<template>
    <div>
        <div class="d-flex justify-content-between align-items-center  mb-4">
           <slot name="topLeft">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 style="color: #008cba;" class="text-slate-900 text-xl tracking-tight font-bold dark:text-gray-300">{{ pageTitle }}</h2>
                </div>
            </slot>
            <slot name="topCenter"></slot>
            <slot name="topRight">
                <div class="-mx-1">
                    <button v-if="apiModelName != null" class="px-3 py-2 border border-green-400 rounded-lg mx-1 dark:bg-gray-800 dark:text-gray-300" @click="exportData">Export</button>
                    <input v-if="externalQuery == null" type="search" v-model="searchQuery" name="query" placeholder="Search" required="required" class="px-4 py-2 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-2 my-3 dark:bg-gray-800 dark:text-gray-300" />
                    <!-- <button class="px-3 py-2 border border-green-400 rounded-lg mx-1 dark:bg-gray-800 dark:text-gray-300" @click="internalIsCardView = !internalIsCardView">{{ internalIsCardView ? 'Table' : 'Card' }} View</button> -->
                    <slot name="createButton" v-if="showCreateButton">   
                        <button  class="px-3 py-2 border border-green-400 rounded-lg mx-1 dark:bg-gray-800 dark:text-gray-300" @click="$router.push(`/${routerUrl}/create`)">Create</button></slot>
                   
                </div>
            </slot>
        </div>
        <slot name="middle">
    
        </slot>
        <div class="rounded-lg overflow-auto my-5 border border-gray-400" v-if="!internalIsCardView">
            <table class="w-100">
                <slot name="tableHeader" :columns="columns">
                    <thead class="rounded-t-lg overflow-hidden">
                        <tr>
                            <th v-if="selectable" class="px-2 py-1 border border-gray-400 border-t-0 dark:text-gray-300 text-center">
                                <input
                                    type="checkbox"
                                    :checked="allSelected"
                                    :indeterminate.prop="allSelectedIndeterminate"
                                    @change="toggleSelectAll" />
                            </th>
                            <th class="px-2 py-1 border border-gray-400 border-t-0 dark:text-gray-300 text-center" :class="{'border-l-0': columnIndex == 0, 'border-r-0': columnIndex == columns.length - 1 && noActions}" v-for="(column, columnIndex) in columns" :key="columnIndex" @click="sortByColumn(column.field)">{{
                                column.label }}</th>
                            <th class="px-2 py-1 border border-gray-400 border-t-0 border-r-0 dark:text-gray-300 text-center" width="150px" v-if="!noActions">Actions</th>
                        </tr>
                    </thead>
                </slot>
                <slot name="tableBody" :rows="tableData" :columns="columns">
                    <template v-if="groupOptions != null && groupOptions['enabled'] == true">
                        <tbody v-if="isLoading">
                            <tr>
                                <td :colspan="columns.length + 1" class="px-2 py-1 border border-gray-400 border-l-0 border-r-0 border-b-0 text-center">
                                    <slot name="loading">Loading...</slot>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="!isLoading && !tableData.length">
                            <tr>
                                <td :colspan="columns.length + 1" class="px-2 py-1 border border-gray-400 border-l-0 border-r-0 border-b-0 text-center">
                                    <slot name="noData">No Data Available</slot>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-for="(row, rowIndex) in tableData" :key="rowIndex" v-else>
                            <tr>
                                <th
                                    v-if="selectable"
                                    class="border-l-0 border-r-0 border border-gray-400 py-2 px-4"
                                    >
                                    <input
                                        type="checkbox"
                                        :indeterminate="checkIfDeterminate(row)"
                                        @change="onCheckboxClickedSelectAllChild($event, row)"
                                        :disabled="row.abtDisabled"
                                        :checked="row.children.filter(wh => wh.abtSelected === true).length == row.children.length"
                                    />
                                </th>
                                <th :colspan="columns.length + 1" class="border-l-0 border-r-0 border border-gray-400 py-2 px-4">{{ row.label }}</th>
                            </tr>
                            <tr v-for="(child, childIndex) in row.children" :key="childIndex">
                                <th
                                    v-if="selectable"
                                        @click.stop="onCheckboxClicked(child, index, $event)"
                                        class="px-2 py-1 border border-gray-400 border-l-0 text-center dark:text-gray-300"
                                    >
                                    <input
                                        type="checkbox"
                                        :disabled="child.abtDisabled"
                                        :checked="child.abtSelected"
                                    />
                                </th>
                                <td v-for="(column, columnIndex) in columns" :key="columnIndex" :class="{'border-b-0': childIndex == row.children.length - 1, 'border-r-0': columnIndex == columns.length - 1 && noActions}" class="px-2 py-1 border border-gray-400 border-l-0 text-center dark:text-gray-300">
                                    <slot name="tableRow" :row="child" :column="column" :index="childIndex">
                                        <span v-if="isDotNotationString(column.field)">
                                            {{ column.field.split('.').reduce((o,i)=> o[i], child) ? column.field.split('.').reduce((o,i)=> o[i], child) : column.defaultValue}}
                                        </span>
                                        <span v-else>
                                            {{ child[column.field] != null ? child[column.field] : column.defaultValue }}
                                        </span>
                                    </slot>
                                </td>
                                <td class="px-2 py-1 border border-gray-400 border-b-0 border-r-0 text-center" v-if="!noActions"> 
                                    <div class="flex">
                                        <slot name="actions" :row="child" :index="childIndex"> 
                                            <RouterLink :to="`/${routerUrl}/details/${child.id}`"  class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300" v-if="hasDetailsPage">
                                                <font-awesome-icon icon="fa-solid fa-eye" />
                                            </RouterLink>
                                            <RouterLink :to="`/${routerUrl}/edit/${child.id}`"  class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300" v-if="hasEditPage">
                                                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                            </RouterLink>
                                            <button @click="deleteRow(child.id, childIndex)"  class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300" v-if="canDeleteRow">
                                                <font-awesome-icon icon="fa-solid fa-trash" />
                                            </button>
                                        </slot>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-else>
                        <tbody>
                            <tr v-if="isLoading">
                                <td :colspan="columns.length + 1" class="px-2 py-1 border border-gray-400 border-l-0 border-r-0 border-b-0 text-center">
                                    <slot name="loading">Loading...</slot>
                                </td>
                            </tr>
                            <tr v-else-if="!isLoading && !tableData.length">
                                <td :colspan="columns.length + 1" class="px-2 py-1 border border-gray-400 border-l-0 border-r-0 border-b-0 text-center">
                                    <slot name="noData">No Data Available</slot>
                                </td>
                            </tr>
                            <tr v-for="(row, rowIndex) in tableData" :key="rowIndex" v-else>
                                <th
                                    v-if="selectable"
                                    @click.stop="onCheckboxClicked(row, index, $event)"
                                    class="border-l-0 border-r-0 border border-gray-400 py-2 px-4"
                                    >
                                    <input
                                        type="checkbox"
                                        :disabled="row.abtDisabled"
                                        v-model="row.abtSelected"
                                    />
                                </th>
                                <td v-for="(column, columnIndex) in columns" :key="columnIndex" :class="{'border-b-0': rowIndex == tableData.length - 1, 'border-r-0': columnIndex == columns.length - 1 && noActions}" class="px-2 py-1 border border-gray-400 border-l-0 text-center dark:text-gray-300">
                                    <slot name="tableRow" :row="row" :column="column" :index="rowIndex">
                                        <span v-if="isDotNotationString(column.field)">
                                            {{ column.field.split('.').reduce((o,i)=> o[i], row) ? column.field.split('.').reduce((o,i)=> o[i], row) : column.defaultValue}}
                                        </span>
                                        <span v-else>
                                            {{ row[column.field] != null ? row[column.field] : column.defaultValue }}
                                        </span>
                                    </slot>
                                </td>
                                <td class="px-2 py-1 border border-gray-400 border-b-0 border-r-0 text-center" v-if="!noActions"> 
                                    <div class="flex">
                                        <slot name="actions" :row="row" :index="rowIndex"> 
                                            <RouterLink :to="`/${routerUrl}/details/${row.id}`"  class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300" v-if="hasDetailsPage">
                                                <font-awesome-icon icon="fa-solid fa-eye" />
                                            </RouterLink>
                                            <RouterLink :to="`/${routerUrl}/edit/${row.id}`"  class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300" v-if="hasEditPage">
                                                <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                            </RouterLink>
                                            <button @click="deleteRow(row.id, rowIndex)"  class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300" v-if="canDeleteRow">
                                                <font-awesome-icon icon="fa-solid fa-trash" />
                                            </button>
                                        </slot>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <tbody ></tbody>

                </slot>
            </table>
        </div>
        <div v-else class="grid md:grid-cols-3 gap-3">
            <div v-for="(row, rowIndex) in tableData" :key="rowIndex" class="border border-gray-400 flex flex-col rounded-lg overflow-hidden">
                <div class="px-2 py-4 border-b boder-black dark:text-gray-300" v-if="getDisplayInHeaderColumn">
                    <slot
                        name="cardHeader"
                        :row="row"
                        :column="getDisplayInHeaderColumn"
                        :index="rowIndex"
                        >
                        <span class="text-slate-900 text-xl font-semibold tracking-tight dark:text-slate-200"> 
                            <template v-if="isDotNotationString(getDisplayInHeaderColumn.field)">
                                {{ getDisplayInHeaderColumn.field.split('.').reduce((o,i)=> o[i], row) ? getDisplayInHeaderColumn.field.split('.').reduce((o,i)=> o[i], row) : getDisplayInHeaderColumn.defaultValue}}
                            </template>
                            <template v-else>
                                {{ row[getDisplayInHeaderColumn.field] != null ? row[getDisplayInHeaderColumn.field] : getDisplayInHeaderColumn.defaultValue}}
                            </template>
                        </span>
                    </slot>
                </div>
                <div class="flex flex-wrap px-2 py-2 flex-1" :class="{'border-b border-gray-400': !noActions}">
                    <div class="w-1/2 my-1 dark:text-gray-300" v-for="column in getColumnsNotInHeader" :key="column">
                        <div class="font-semibold">{{ column.label }}</div>
                        <div>
                            <slot name="cardRow" :row="row" :column="column" :index="rowIndex">
                                <template v-if="isDotNotationString(column.field)">
                                    {{ column.field.split('.').reduce((o,i)=> o[i], row) ? column.field.split('.').reduce((o,i)=> o[i], row) : column.defaultValue}}
                                </template>
                                <template v-else>
                                    {{ row[column.field] != null ? row[column.field] : column.defaultValue }}
                                </template>
                            </slot>
                        </div>
                    </div>
                </div>
                <div class="h-12 flex items-center justify-center" v-if="!noActions">
                    <slot name="cardActions" :row="row" :index="rowIndex"> 
                        <RouterLink :to="`/${routerUrl}/details/${row.id}`" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300" v-if="hasDetailsPage">
                            <font-awesome-icon icon="fa-solid fa-eye" />
                        </RouterLink>
                        <RouterLink :to="`/${routerUrl}/edit/${row.id}`" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                        </RouterLink>
                        <button @click="deleteRow(row.id, rowIndex)"  class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
                            <font-awesome-icon icon="fa-solid fa-trash" />
                        </button>
                    </slot>
                </div>
            </div>
        </div>
        <div class="my-4 flex -mx-2 items-center" v-if="!isLoading">
            <button class="dark:bg-gray-800 dark:text-gray-300 flex items-center px-4 py-2 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-2 disabled:opacity-50 disabled:cursor-not-allowed" :disabled="page === 1" @click="page--">« Prev</button>
            <button class="dark:bg-gray-800 dark:text-gray-300 flex items-center px-4 py-2 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-2 disabled:opacity-50 disabled:cursor-not-allowed" :disabled="(page === pageCount) || (pageCount === 0)" @click="page++">Next »</button>
            <select v-model="internalPageSize" class="flex items-center px-4 py-2 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-2 dark:bg-gray-800 dark:text-gray-300">
                <option v-for="size in pageSizes" :key="size" :value="size">
                    {{ size }}
                </option>
            </select>
            <span class="mx-2 dark:text-gray-300">
                Showing {{ (page - 1) * internalPageSize + 1 }} -
                {{ Math.min(page * internalPageSize, totalCount) }} of {{ totalCount }}
            </span>
        </div>
    </div>
</template>
  
<script>
import stringUtil from "@/utils/string.js"; // <-- import
import { storeUtils } from '../utils/store.js'

export default {
    name: 'ABTable',
    props: {
        selectOptions: {
            default() {
                return {
                    enabled: false,
                    selectionInfoClass: '',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: false,
                    selectAllByGroup: false,
                };
            },
        },
        groupOptions:{
            type: Object,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isCardView:{
            type: Boolean,
            default: false
        },
        showCreateButton:{
            type: Boolean,
            default: true
        },
        hasDetailsPage: {
            type: Boolean,
            default: false
        },
        hasEditPage: {
            type: Boolean,
            default: true
        },
        canDeleteRow: {
            type: Boolean,
            default: true
        },
        hasPreviousPage:{
            type: Boolean,
            default: false
        },
        hasNextPage:{
            type: Boolean,
            default: false
        },
        noActions:{
            type: Boolean,
            default: false
        },
        columns: {
            type: Array,
            default: () => {
                return  [
                    { label: "ID", field:"id", visible: true },
                    { label: "Name", field:"name", visible: true },
                ]
            }
        },
        externalQuery: {
            type: String,
            default: null,
        },
        tableData: {
            type: Array,
            default: () => {
                return  []         
            }
        },
        totalCount: {
            type: Number,
            default: 0
        },
        pageTitle: {
            type: String,
            default: 'Entities'
        },
        routerUrl: {
            type: String,
            default: 'entity'
        },
        pageSize: {
            type: Number,
            default: 10
        },
        pageSizes:{
            type: Array,
            default: () => [5, 10, 20, 100]
        },
        filters: {
            type: Object,
            default: () => {}
        },
        apiModelName: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            windowWidth: 0,
            searchQuery: "",
            page: 1,
            sortBy: "Id",
            sortDirection: "desc",
            internalIsCardView: false,
            internalPageSize: 0
        }
    },
    computed:{
        allSelectedIndeterminate(){
           return this.selectedRowsCount > 0 && (this.selectedRowsCount < this.getPageCount)
        },
        getPageCount(){
            let pageRows = []
            for(let r = 0; r < this.tableData.length; r++){
                const row = this.tableData[r]

                if(row.hasOwnProperty("children")){
                    for(let c = 0; c < row.children.length; c++){
                        let getChild = row.children[c]
                        pageRows.push(getChild)
                    }
                } else {
                    pageRows.push(row)
                }
            }
            return pageRows.length;
        },
        allSelected() {
            return (
                this.selectedRowsCount > 0 && (this.selectedRowsCount === this.getPageCount)
            );
        },
        selectedRows(){
            let selectedRows = []
            for(let r = 0; r < this.tableData.length; r++){
                const row = this.tableData[r]

                if(row.hasOwnProperty("children")){
                    const getSelectedRows = row.children.filter(wh => wh.abtSelected === true)
                    for(let c = 0; c < getSelectedRows.length; c++){
                        let getChild = getSelectedRows[c]
                        selectedRows.push(getChild)
                    }
                } else {
                    if(row.abtSelected){
                        selectedRows.push(row)
                    }

                }
            }
            return selectedRows;
        },
        selectedRowsCount(){
            return this.selectedRows.length
        },
        getColumnsNotInHeader(){
            return this.columns.filter(column => !column.displayInHeader)
        },
        getDisplayInHeaderColumn(){
            return this.columns.find(column => column.displayInHeader)
        },
        
        pageCount(){
            return Math.ceil(this.totalCount / this.internalPageSize);
        },
    },
    methods: {
        emitSelectedRows() {
            this.$emit('on-select-all', {
                selected: this.selectedRowCount === this.totalCount,
                selectedRows: this.selectedRows,
            });
        },
        unselectAllInternal() {
            for(let r = 0; r < this.tableData.length; r++){
                const row = this.tableData[r]

                if(row.hasOwnProperty("children")){
                    for(let c = 0; c < row.children.length; c++){
                        let getChild = row.children[c]
                        getChild.abtSelected = false
                    }
                } else {
                    row.abtSelected = false
                }
            }
            this.emitSelectedRows();
        },

        toggleSelectAll() {
            console.log(this.allSelected)
            if (this.allSelected) {
                this.unselectAllInternal();
                return;
            }
            this.tableData.forEach( (tbd) => {
                if(tbd.hasOwnProperty("children")){
                    tbd.children.forEach( (child) => {
                        child.abtSelected = true
                    })
                }
                tbd.abtSelected = true
            })
            this.emitSelectedRows();
        },
        checkIfDeterminate(row){
            if(row.hasOwnProperty("children")){
                const getSelectedRows = row.children.filter(wh => wh.abtSelected === true)
                if(getSelectedRows.length == 0) {
                    row.abtSelected = false;
                    return false;
                }
                return getSelectedRows.length < row.children.length;
            } else {
            }

        },
        onCheckboxClickedSelectAllChild(event, row) {
            row.abtSelected = event.target.value
            if(this.checkIfDeterminate(row)){
                for(let c = 0; c < row.children.length; c++){
                    let getChild = row.children[c] 
                    getChild['abtSelected'] = true
                }
            } else {
                for(let c = 0; c < row.children.length; c++){
                    let getChild = row.children[c] 
                    getChild['abtSelected'] = !row.abtSelected
                }
            }
            

            this.emitSelectedRows();
        
        },
        onCheckboxClicked(row, index, event) {
            row['abtSelected'] = !row.abtSelected

            this.$emit('on-row-click', {
                row,
                pageIndex: index,
                selected: !!row.abtSelected,
                event,
            });

            this.emitSelectedRows();
        },
        initializeSelect() {
            const {
                enabled,
            } = this.selectOptions;

            if (typeof enabled === 'boolean') {
                this.selectable = enabled;
            }

        
        },
        reload(){
            this.page = 1;
            this.fetchRemoteData()
        },
        exportData(){
            storeUtils.export(this.apiModelName)
        },  
        isDotNotationString(str){
            return str.match(/\.[^.]/) && !str.match(/\s/)
        },
        toggleCardView(){
            this.internalIsCardView = !this.internalIsCardView
        },
        async deleteRow(id, rowIndex) {
            this.$emit('onDeleteRow', id, rowIndex)
        },
        camelCaseToTitleCase(string) {
            return stringUtil.camelCaseToTitleCase(string);
        },
        async fetchRemoteData() {
            this.$emit('onFetchRemoteData',
                {
                    page: this.page,
                    pageSize: this.internalPageSize,
                    sortBy: this.sortBy,
                    sortDirection: this.sortDirection,
                    searchQuery: this.searchQuery,
                    filters: this.filters
                })
        },
        sortByColumn(column) {
            if(!column.sortable) return;
            if (this.sortBy === this.camelCaseToTitleCase(column.field)) {
                this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc"
            }
            else {
                this.sortBy = this.camelCaseToTitleCase(column.field)
                this.sortDirection = "asc"
            }
        }
    },
    async created() {
        this.internalIsCardView = this.isCardView
        this.internalPageSize = this.pageSize

    },
    mounted() {
        this.windowWidth = window.innerWidth
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    },
    watch: {
        selectOptions: {
            handler() {
                this.initializeSelect();
            },
            deep: true,
            immediate: true,
        },
        filters(){
            this.page = 1;
            this.fetchRemoteData();
        },
        windowWidth(windowWidth){
            // this.internalIsCardView = windowWidth <= 1023
        },
        async externalQuery() {
            this.searchQuery = this.externalQuery
        },
        async searchQuery(searchQuery) {
            await this.fetchRemoteData();
        },
        async page() {
            await this.fetchRemoteData();
        },
        async internalPageSize() {
            this.page = 1;
            await this.fetchRemoteData();
        },
        async sortBy() {
            await this.fetchRemoteData();
        },
        async sortDirection() {
            await this.fetchRemoteData();
        },
    },
}
</script>

<!-- 
    Pagination
    Search
    Sort
    Configure Display Columns
    Save display columns / sorting
    dynamically specify store
    fully generalize
 -->
<!-- //Note for Later
Request display columns from the db,
and provide a UI to manage it
-->