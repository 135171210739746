<template>
  <nav class="navbar fixed-top">
  <div class="container">
    <!--<router-link to="/" class="navbar-brand">Inspectra TSS</router-link>--->
    <router-link to="/upload" class="upload-btn">Upload</router-link>
    <button class="navbar-toggler" @click="toggleNavbar"> ☰ </button> <!-- Burger menu icon -->
    <ul class="navbar-menu" :class="{'show': isNavbarOpen}">
      <li><router-link to="/employee" class="navbar-link">Employee</router-link></li>
      <li><router-link to="/" class="navbar-link">Issued</router-link></li>
      <li><router-link to="/returned" class="navbar-link">Returned</router-link></li>
      <li><router-link to="/DeliveryNote/Valid" class="navbar-link">Received</router-link></li>
      <li><router-link :to="`/QualifiersTab`" class="navbar-link">Qualifiers</router-link></li>
      <li><router-link :to="`/InStock`" class="navbar-link">Stock</router-link></li>
      <li><router-link :to="`/PastDelivery`" class="navbar-link">Past Delivery Notes</router-link></li>
      <li><router-link :to="`/EmployeeHours`" class="navbar-link">Salary</router-link></li>
    </ul>
    <div>
      <span class="loginUser">{{ storeUtils.getLoggedInUser().username }}</span>
      <button @click="logOut()" class="logout-btn">Log out</button>
    </div> 
  </div>
</nav>

<div style="padding-top:4.5em;">
  <slot />
</div>
<p class="d-none">20240127-v1</p>
</template>


<script>
import { storeUtils } from '../utils/store.js'
import Cookie from 'js-cookie'

export default {
  data(){
    return {
      storeUtils,
      isNavbarOpen: false // Controls the visibility of the navbar menu
    }
  },
  methods: {
      logOut(){
        Cookie.remove("authToken");
        Cookie.remove("loggedInUser");
        this.$router.replace("/login");
      },
      toggleNavbar() { // Correctly placed inside methods
        this.isNavbarOpen = !this.isNavbarOpen; // Toggle the visibility of the navbar menu
      }
    }
}
</script>
<style scoped>
@import "@/assets/css/style.css";

.navbar {
  background-color: #008cba; /* Blue background */
  color: rgb(247, 228, 228);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0.5em; 
}

.navbar-toggler {
  display: none; /* Hide burger menu icon on large screens */
  background: none;
  border: none;
  color: #fff; /* Adjust the color as needed */
  font-size: 1.5em;
  cursor: pointer;
}

.navbar-menu {
  list-style-type: none;
  display: flex; /* Default display for large screens */
  margin: 0;
  padding: 0;
}

.navbar-menu li, .loginUser {
  margin: 0 3px;
}

.navbar-link {
  text-decoration: none;
  font-size: 1em;
  color: rgb(247, 228, 228);
  padding: 0.2em 0.8em;
}

.navbar-link:hover {
  color: #b2dfdb;
}

.upload-btn, .logout-btn {
  padding: 0.3em 0.8em; /* Padding */
}

@media (max-width: 1218px) {
  .navbar-toggler {
    display: block; /* Show the toggle button */
  }

  .navbar-menu {
    display: none; /* Hide the menu initially */
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 50px; /* Adjust based on your navbar's height */
    background-color: #008cba; /* Menu background on small screens */
    width: 100%; /* Full width for small screens */
    z-index: 1000;
  }

  .navbar-menu.show {
    display: flex; /* Show the menu when toggled */
  }
}
.loginUser{
  margin: 0 3px;
  font-size: 1em;
  color: rgb(248, 184, 184);
  padding: 0.2em 0.8em;
}

.upload-btn {
  margin-right: 2px; 
  font-weight: bold;
  color: rgb(247, 228, 228);
  background-color: #4caf50;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.upload-btn:hover {
  background-color: #43a047;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.upload-btn, .logout-btn {
  padding: 0.3em 0.8em; /* Reduced padding */
}

.logout-btn {
  margin-left: 2px; 
  font-weight: bold;
  color: rgb(247, 228, 228);
  background-color: #f44336;
  text-decoration: none;
  padding: 0.5em 1.2em;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.logout-btn:hover {
  background-color: #e53935;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
