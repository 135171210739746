import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'
import { el } from 'date-fns/locale';

export const useYearlyRatesStore = defineStore({
  id: 'goodsreturned',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchYearlyRate() {
      const yearlyRate = await storeUtils.fetch('YearlyRates/GetRates')
      return yearlyRate;
    },
    async createYearlyRate(YearlyRate) {
        const yearlyRate = await storeUtils.create(`YearlyRates`, YearlyRate)
        return yearlyRate;
    },
    async updateQualifier(YearlyRate) {
      const yearlyRate = await storeUtils.update(`YearlyRates`, YearlyRate)
      return yearlyRate;
    },

    async fetchRates(){
      const Good = await storeUtils.fetch('YearlyRates/GetinHouseRate')
      return Good;
    },
}

})