import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useAuthStore = defineStore("auth", {
  state: () => ({
    // user: null,
  }),

  actions: {
    async getMyUser() {
      return await storeUtils.fetch('User/me')
    },
    async login(formData) {
      return await storeUtils.create('User/authenticate', formData)
    }
  },
});