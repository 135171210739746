<template>
  <ABTable class="px-6" ref="goodsTable" :isLoading="isLoading" @onFetchRemoteData="fetchRemoteData()"
      :table-data="rows" :page-title="pageTitle" :router-url="routerUrl" :filters="filters"
      :columns="filteredColumns" :select-options="{enabled: false}" @on-select-all="selectAll"
      :total-count="totalCount" :hasDetailsPage="true" :no-actions="true" :page="page" :pageSize="pageSize"
      :showSelectionColumn="true" :external-query="false">
      <template v-slot:createButton>
        <div class="button-container">
          <h2 id="deliveryNumber" class="delivery-number">
            Delivery Number: {{deliveryId}}
          </h2>
          <div class="right-container">
            <button @click="toggleColumns()" id="extraColbtn" class="btn-action" type="button">
              {{ showAdvancedColumns ? 'Hide Extra Columns' : 'Show More Columns' }}
            </button>
            <button id="export" class="btn-action" @click="exportToCSV">Export CSV</button>
            <button class="btn-primary btn-save" @click="printContent" style="background-color: blue;">Print</button>
          </div>
        </div>
      </template>
      <template v-slot:actions="{row}">
        <div class="flex items-center justify-center w-full h-full">
          <button @click="modalShowned = true; selectedRow = row" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
             <i class="bi bi-pencil"></i>
          </button>
        </div>
      </template>
  </ABTable>

  <div id="printable-content" style="display: none;">
    <div class="w-100 to-deliver">
      <table class="print-table" style="border-collapse: collapse; text-align: center;">
        <thead>
          <tr>
            <th colspan="100%">
              <div class="print-header d-flex justify-content-between">
                <div class="col-md-6">
                  <div style="font-weight: bold; font-size: 25px;">Despatched to Trelleborg</div>
                  <div id="deliveryDetails" style="font-size: 16px;">
                    Delivery Number: {{deliveryId}}
                    <br/>
                    Date: {{ currentDate }}
                  </div>
                </div>
                <div class="printOnly col-md-6 text-right">
                  <div class="address-logo">
                    <div>
                      <img src="../assets/inspectra-logo-dark.png" alt="Inspectra Logo" class="img-fluid" style="max-width: 100px"/>
                    </div>
                    <div class="address">
                      MRA49B, Marsa Industrial Estate,<br />
                      Marsa.<br />
                      VAT 17434222
                    </div>
                  </div>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th v-for="(column, columnIndex) in columns" :key="columnIndex">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td :colspan="columns.length + 1" class="text-center">Loading...</td>
          </tr>
          <tr v-else-if="!isLoading && !rows.length">
            <td :colspan="columns.length + 1" class="text-center">No Data Available</td>
          </tr>
          <tr v-for="(row, rowIndex) in rows" :key="rowIndex" v-else>
            <td v-for="(column, columnIndex) in columns" :key="columnIndex" :data-field="column.field">
              {{ row[column.field] != null ? row[column.field] : '' }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="100%" style="padding-top: 50px;">
              <div style="display: flex;">
                <div class="footer-text" style="flex: 1; margin: 0 10px; border-top: 1px solid black; text-align: center;">Delivered on time By</div>
                <div class="footer-text" style="flex: 1; margin: 0 10px; border-top: 1px solid black; text-align: center;">Checked by</div>
                <div class="footer-text" style="flex: 1; margin: 0 10px; border-top: 1px solid black; text-align: center;">Received on time By</div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import ABTable from '../components/ABTable.vue'
import { useDeliveryNotesStore } from '@/stores/deliveryNote.js';
import { useGoodsStore } from '@/stores/deliveryNote.js'
import { useToast } from "vue-toast-notification";
import FormComponent from '@/components/FormComponent.vue'
import { useBagInventoryStore } from '@/stores/bagInventory.js'
import { useEmployeeStore } from '@/stores/employees.js'
import { parse, format } from 'date-fns';
const $toast = useToast();

export default {
  name: 'Issued',
  components: {
      ABTable,
      FormComponent
  },
  data() {
      return {
          format,
          parse,
          deliveryId: "",
          currentDate: new Date().toLocaleDateString('en-GB'), // Add this line
          showAdvancedColumns: false,
          remoteParams:{},
          modalShowned: false,
          modalReceivedShowned: false,
          isLoading: false,
          filters:{},
          rows: [],
          selectedRow: {
            searchQuery: "",
            page: 1,
            pageSize: 3,
            pageSizes: [3, 10, 20, 100],
            totalCount: 0,
          },
          sortBy: "Id",
          sortDirection: "asc",
          pageTitle: 'Bag Details',
          columns: [
            //{label: "Id", field: "id",type: "number",width: "5%", sortable: false},
            {label: "Bag_No",field: "bagNumber", sortable: false},
            {label: "MO Number", field: "moNumber", sortable: false },
            {label: "Chit Number", field: "chitNumber", sortable: false},
            {label: "Part Number",field: "partNumber", sortable: false},
            {label: "Supplier", field: "supplier", sortable: false, },
            {label: "Inspection Type",field: "inspectionType", sortable: false},
            //{label: "Quality", field: "quality", sortable: false},
            {label: "Issued",field: "quantityIssued",type: "number", sortable: false},
            {label: "Passed",field: "quantityPassed",type: "number", sortable: false },
            {label: "Failed", field: "quantityFailed", type: "number", sortable: false },
          ],
          codes: [],
          users: [],
      }
  },
 created() {
    this.fetchEmployee();
    this.deliveryId = localStorage.getItem("selectedRowId"); // Retrieve the ID from localStorage
  },
  computed: {
    filteredColumns() {
      if (this.showAdvancedColumns) {
          return this.columns; // Show all columns when true
      } else {
          // Hide specific columns when false
          return this.columns.filter(column => !['id', 'moNumber', 'chitNumber','supplier'].includes(column.field));
      }
    }
  },
  methods: {
    navigateToDeliver() {
    this.$router.push({ name: 'toDeliver', params: { ids: this.deliveryId } });
  },
    toggleColumns() {
      this.showAdvancedColumns = !this.showAdvancedColumns;  // Toggle the state
    },
    async fetchRemoteData(remoteParams) {
        if (!this.$route.params.ids) return;
        const ids = this.$route.params.ids.split(",");
        const filters = ids.map((id) => `ids=${id}`).join("&");
        console.log(filters);
        const DeliveryNotesStore = useDeliveryNotesStore();
        const response = await DeliveryNotesStore.fetchSelectedDeliveryNotes(filters);
        if (response.ok) {
            const data = await response.json();
            // Sort the data by bagNumber
            this.rows = data.sort((a, b) => {
                if (a.bagNumber < b.bagNumber) return -1;
                if (a.bagNumber > b.bagNumber) return 1;
                return 0;
            });
        }
        this.isLoading = false;
},

  async fetchEmployee(){
    const employeeStore = useEmployeeStore()
    await employeeStore
        .fetchEmployees()
        .then(async (res) => {
        if (res.ok) {
          this.users = await res.json();
          this.users.sort((a, b) => a.name.localeCompare(b.name));
        } else $toast.error("Error fetching Employees");
      })
      .catch((err) => {
        $toast.error("Error fetching Employees") + err;
      });
    },
  async EditBag(row, isEdit = false){
      console.log(row)
      const goodStore = useGoodsStore()
      await goodStore.updateDeliveryNotePrinted(row, isEdit ? row.id : null)
        .then(async (result) => {
        if (result.status >= 200 && result.status <= 205) {
          $toast.success(`Bag ${row.bagNumber} Edited`);
          if(isEdit){
            this.modalShowned = false
          }
          else{
            this.modalReceivedShowned = false
          }
          this.$refs.goodsTable.reload()
        } else{
          let error = await result.text();
          console.log(error)
          $toast.error("Error editing bag. Error: " + error)
        }
      })
      .catch((err) => $toast.error("Error editing bag") + err);
    },
    exportToCSV() {
      console.log("Exporting to CSV");

      // Specify the columns to be exported
      const exportColumns = [
          { label: "Delivery Id", field: "deliveryId" }, 
          { label: "Bag Number", field: "bagNumber" },
          // { label: "MO Number", field: "moNumber" },
          { label: "Part Number", field: "partNumber" },
          { label: "Quantity Issued", field: "quantityIssued" },
          { label: "Quantity Passed", field: "quantityPassed" },
          { label: "Quantity Failed", field: "quantityFailed" }
      ];

      // Sort the rows by Bag Number
      const sortedRows = this.rows.sort((a, b) => {
          if (a.bagNumber < b.bagNumber) return -1;
          if (a.bagNumber > b.bagNumber) return 1;
          return 0;
      });

      // Create the CSV data rows
      const csvData = sortedRows.map(row =>
          [this.deliveryId, ...exportColumns.slice(1).map(column => row[column.field] != null ? row[column.field] : '')].join(",")
      );

      // Combine data rows into a single CSV string
      const csvContent = csvData.join("\n");

      // Create a Blob from the CSV string and generate a download link
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.deliveryId + ".csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  },


  printContent() {
  const printContents = document.getElementById("printable-content").innerHTML;
  const originalContents = document.body.innerHTML;

  /* Function to split part number
  function splitPartNumber(partNumber) {
    const maxLength = 10; // Adjust this value as needed
    if (partNumber.length > maxLength) {
      const mid = Math.floor(partNumber.length / 2);
      return partNumber.slice(0, mid) + '-' + partNumber.slice(mid);
    }
    return partNumber;
  }*/

  // Clone the printable content to manipulate it
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = printContents;
  
  /* Find all part number cells and split them if necessary
  tempDiv.querySelectorAll('td[data-field="partNumber"]').forEach(td => {
    td.textContent = splitPartNumber(td.textContent);
  });*/

  document.body.innerHTML = tempDiv.innerHTML;
  document.body.pageSize = "A4";
  document.body.style.fontSize = "12px";
  window.print();
  document.body.innerHTML = originalContents;
  window.location.reload(); // Refresh the page to restore the original content
}

  }
}
</script>

<style scoped>
@import url("https://unpkg.com/css.gg@2.0.0/icons/css/trash.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

.table-container {
  max-width: 100%; /* Adjust this value as needed for your layout */
  margin: auto; /* This centers the div */
  padding: 20px; /* Optional: Adds some spacing inside the div */
  box-shadow: 0px 0px 5px #ccc; /* Optional: Adds a subtle shadow for better aesthetics */
}

#printable-content{
  display: none;
}
.button-container {
  display: flex;
  justify-content: space-between; /* Adjust alignment */
  align-items: center; /* Center items vertically */
  gap: 0.5rem;
}

#extraColbtn, #export {
  background-color: hsl(83, 70%, 41%);
  color: white;
  text-decoration: none;
  padding: 10px 20px; /* Ensure consistent button size */
  border-radius: 5px;
  transition: background-color 0.3s;
}

#extraColbtn {
  background-color: hsl(83, 70%, 41%);
}

#export {
  background-color: hsl(9, 90%, 51%);
}

.input-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field,
.select-field {
  width: 150px;
  margin-bottom: 5px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-field {
  margin-bottom: 0;
}

.select-field {
  margin-top: 0;
}

.delivery-number {
  font-size: 16px;
}

.btn-save, .btn-action {
  margin-top: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px; /* Ensure consistent button size */
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.printOnly {
  display: none;
}

@media print {
    .printOnly {
        display: block !important;
    }

    .print-table thead th {
        background-color: white !important;
        color: black !important;
    }

    .print-table thead th[colspan="100%"] {
        border-bottom: 2px solid black;
    }

    .print-table {
        page-break-inside: auto;
        width: 100%;
    }

    .print-table thead {
        display: table-header-group;
    }

    .print-table tbody tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    .print-table tfoot {
        display: table-footer-group;
    }
}
</style>