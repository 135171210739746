import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useEmployeeStore = defineStore({
  id: 'employee',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchEmployees() {
      const Employee = await storeUtils.fetch('Employee')
      return Employee;
    },
    async searchEmployees(page, pageSize, sortBy, sortDirection, searchQuery) {
      const Employee = await storeUtils.search('Employee', page, pageSize, sortBy, sortDirection, searchQuery)
      return Employee
    },

    async fetchEmployee(id) {
      const Employee = await storeUtils.fetchOne('Employee', id)
      return Employee;
    },
 
    async createEmployee(Employee) {
      const employee = await storeUtils.create(`Employee`,Employee)
      return employee;
    },

    async updateEmployee(Employee) {
      const employee = await storeUtils.update('Employee', Employee)
      return employee;
    },
    
    async deleteEmployee(id) {
      const employee = await storeUtils.delete('Employee', id)
      return employee;
    },
  },
})