import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'
import { el } from 'date-fns/locale';

export const useQualifiersTypeStore = defineStore({
  id: 'QualifiersType',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchQualifierType() {
      const type = await storeUtils.fetch('QualifiersType')
      return type;
    },
    async fetchRetunedGoods() {
      const Goods = await storeUtils.fetch('QualifiersType')
      return Goods;
    },
}

})