import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useDeliveryNotesStore = defineStore({
  id: 'DeliveryNotes',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchDeliveryNotes() {
      const DeliveryNotes = await storeUtils.fetch('DeliveryNote/Valid')
      return DeliveryNotes;
    },
    async searchDeliveryNotes(page, pageSize, sortBy, sortDirection, searchQuery) {
      const DeliveryNotes = await storeUtils.search('DeliveryNote', page, pageSize, sortBy, sortDirection, searchQuery)
      return DeliveryNotes
    },

    async fetchDeliveryNote(id) {
      const DeliveryNotes = await storeUtils.fetchOne('DeliveryNote/', id)
      return DeliveryNotes;
    },

    async createDeliveryNote(deliveryNotes) {
      const DeliveryNotes = await storeUtils.create(`DeliveryNote`, deliveryNotes)
      return DeliveryNotes;
    },
    
 
    async updateDeliveryNote(deliveryNotes) {
      const DeliveryNotes = await storeUtils.update(`DeliveryNote`, deliveryNotes)
      return DeliveryNotes;
    },
    
    async deleteDeliveryNote(id) {
      const DeliveryNotes = await storeUtils.delete('DeliveryNote', id)
      return DeliveryNotes;
    },

    async fetchSelectedDeliveryNotes(ids) {
      const DeliveryNotes = await storeUtils.fetch(`DeliveryNote/Valid/GetSelectedRows?${ids}`)
      return DeliveryNotes;
    },

    async fetchDeliveryIdPrintedByDeliveryId(bagId) {
      const DeliveryNotes = await storeUtils.fetch(`DeliveryNotePrinted/GetDeliveryIdByBagNo/${bagId}`)
      return DeliveryNotes;
    },
    
  },
})