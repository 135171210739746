import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'
import { el } from 'date-fns/locale';

export const useGoodsReturnedStore = defineStore({
  id: 'goodsreturned',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchGoods() {
      const Goods = await storeUtils.fetch('BagInventory/GetUploadedBags')
      return Goods;
    },
    async fetchRetunedGoods() {
      const Goods = await storeUtils.fetch('BagInventory/GetReturnedBags')
      return Goods;
    },

    async searchRetunedGoods(page, pageSize, sortBy, sortDirection, searchQuery) {
      const Goods = await storeUtils.search('search', page, pageSize, sortBy, sortDirection, searchQuery)
      return Goods
    }
}

})