import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import router from "./router";
import 'vue-toast-notification/dist/theme-sugar.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import MenuBar from './components/MenuBar.vue';

const vuetify = createVuetify({
  components,
  directives,
});

const pinia = createPinia()

// The correct method name is "component," not "components"
const app = createApp(App);

app.config.globalProperties.apiPrefix = process.env.NODE_ENV == 'development' ? 'https://localhost:7164' : '/api';

app
  .use(vuetify)
  .use(router)
  .use(pinia)
  .use(VueSweetalert2)
  .component('MenuBar', MenuBar) // Use "component" to register components
  .mount("#app");
