<template>
  <ABTable class="px-6" ref="goodsTable" :isLoading="isLoading" @onFetchRemoteData="fetchRemoteData" :table-data="rows" :page-title="pageTitle" :router-url="routerUrl" :filters="filters"
        :columns="columns" :total-count="totalCount" :hasDetailsPage="true" @onDeleteRow="deleteRow" :no-actions="true" >
        <template v-slot:createButton>
          <button class="btn-save float-right my-3" @click="exportToCSV">Export CSV</button>
        </template>
        <template v-slot:tableRow="{ row, column}">
          <span v-if="column.field == 'assignedDate'">
              {{ row.assignedDate != null ? format(parse(row.assignedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS", new Date()), 'dd/MM/yyyy') : ' ' }}
          </span>
    </template>
  </ABTable>
</template>

<script>
export const apiInstance = axios.create({
  responseType: "json",
  headers: {
    //'Content-Type': 'application/json',
    // you dont need to pre-define all headers
  },
});
const token =
"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiMSIsInJvbGUiOiJVc2VyIiwibmJmIjoxNjk0MDg2ODM1LCJleHAiOjE2OTQ2OTE2MzUsImlhdCI6MTY5NDA4NjgzNSwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NzE2NCIsImF1ZCI6Imh0dHBzOi8vbG9jYWxob3N0OjcxNjQifQ.buxKyk5Tv_o5J3syVWZuziFJ46ZXCxnp2G6XV9yPJ3g";
apiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
import { useBagInventoryStore } from '@/stores/bagInventory.js'
import axios from "axios";
import { useToast } from "vue-toast-notification";
const $toast = useToast();
import { parse, format } from 'date-fns';

import ABTable from '../components/ABTable.vue'

export default {
  name: "GoodsTable",
  components: 
  {
    ABTable
  },

  data() {
    return {
      parse, format,
      pageTitle: "Stock",
      columns: [
        {label: "Id",field: "id",type: "number",width: "5%",},
        {label: "Bag Number",field: "bagNumber",},
        {label: "MO_Number",field: "moNumber",},
        {label: "Chit Number",field: "chitNumber",},
        {label: "Part Number",field: "partNumber",},
        {label: "Supplier",field: "supplier",},
        {label: "Inspection Type",field: "inspectionType",},
        {label: "Quality",field: "quality", },
        {label: "Quantity Issued",field: "quantityIssued",type: "number",},
        {label: "Date Issued",field: "dateIssued",type: "string",},
        {label: "Requested Date",field: "requestedDate",inputFormat: "DD/MM/YYYY",outputFormat: "DD/MM/YYYY",},
        {label: "Assigned To", field: "assignedTo",},
        {label: "Second Assigned To",field: "bx2AssignedTo", sortable: false},
        {label: "Assigned Date",field: "assignedDate",inputFormat: "DD/MM/YYYY", outputFormat: "DD/MM/YYYY",},
        {label: "Issued",field: "isChecked",},
        {label: "Received ",field: "isReturned",},
        
      ],
      isLoading: false,
      rows: [],
      codes: [],
      users: [],
    };
  },
  methods: {
    async fetchRemoteData(remoteParams) {
      this.remoteParams = remoteParams
        this.isLoading = true
        const BagInventoryStore = useBagInventoryStore()
        const dataResponse = await BagInventoryStore
            .searchBagInventoryWhatsInStock(remoteParams.page, remoteParams.pageSize, remoteParams.sortBy, remoteParams.sortDirection, remoteParams.searchQuery)
        this.rows = await dataResponse.items
        this.totalCount = await dataResponse.totalCount
        this.isLoading = false
    },
    async fetchAllData() {
      this.isLoading = true;
      const BagInventoryStore = useBagInventoryStore();
      let allData = [];
      let page = 1;
      const pageSize = 100; // Adjust page size if necessary
      let totalCount = 0;
      do {
        const dataResponse = await BagInventoryStore
          .searchBagInventoryWhatsInStock(page, pageSize, this.remoteParams.sortBy, this.remoteParams.sortDirection, this.remoteParams.searchQuery);
        allData = allData.concat(dataResponse.items);
        totalCount = dataResponse.totalCount;
        page++;
      } while (allData.length < totalCount);
      this.isLoading = false;
      return allData;
    },
    async exportToCSV() {
      const allRows = await this.fetchAllData();
      const csvHeader = this.columns.map((column) => column.label).join(",");
      const csvData = allRows.map((row) =>
        this.columns.map((column) => row[column.field] != null ? row[column.field] : "").join(",")
      );
      const csvContent = [csvHeader, ...csvData].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "table_data.csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style>
.btn-save {
  margin-top: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
</style>
