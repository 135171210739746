<template>
    <div>
        <form @submit.prevent="submitForm">
            <div class="flex flex-wrap -mx-1">
                <template v-for="(type, key) in renderableDataTypes" :key="key">
                    <slot name="formInput" :formData="formData" :inputName="key"
                            :title="camelCaseToTitleCase(key)" v-if="!systemFields.includes(key)">
                        <div class="mb-5 px-1 md:w-1/3 w-full">
                            <label :for="key" class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2"><strong>{{ camelCaseToTitleCase(key) }}</strong></label>
                            <input v-if="inputType(type) == 'number'" step="0.01" :id="key" :name="key" :type="inputType(type)" v-model="formData[key]" class="w-full px-3 py-2 border border-blue-400 focus:outline-0 rounded-lg h-12 dark:bg-gray-800 dark:text-gray-300" @input="$emit('input', formData)"/>
                            <input v-else :id="key" :name="key" :type="inputType(type)" v-model="formData[key]" class="w-full px-3 py-2 border border-blue-400 focus:outline-0 rounded-lg h-12 dark:bg-gray-800 dark:text-gray-300" @input="$emit('input', formData)"/>
                        </div>
                    </slot>
                </template>
            </div>
            <button v-if="showSubmitButton" class="h-12 w-48 border border-green-400 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed mb-5 dark:bg-gray-800 dark:text-gray-300" type="submit" :disabled="submitButtonClicked">
                Submit{{  submitButtonClicked ? 'ting' : '' }}
            </button>
        </form>
    </div>
</template>
  
<script>
import stringUtil from "../utils/string.js"; // <-- import
import { parse, isValid } from 'date-fns';
export default {
    emits: ["submit"],
    name: "FormComponent",
    props: {
        inputValues: {
            type: Object,
            required: true,
        },
        showSubmitButton: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        renderableDataTypes() {
            const renderableDataTypes = {};
            for (let key in this.dataTypes) {
                if (this.dataTypes[key] === 'number' || this.dataTypes[key] === 'boolean' || this.dataTypes[key] === 'datetime' || this.dataTypes[key] === 'string' || this.dataTypes[key] === 'date') {
                    renderableDataTypes[key] = this.dataTypes[key];
                }
            }
            return renderableDataTypes;
        },
    },
    data() {
        return {
            submitButtonClicked: false,
            dataTypes: {},
            formData: {},
            internalInputValues: {},
            systemFields : ['id', 'createdDate', 'updatedDate', 'createdBy', 'updatedBy']

        };
    },
    mounted() {
        this.internalInputValues = { ...this.inputValues }
        if(this.$route.params.entity){
            this.internalInputValues = { ...this.$route.params.entity }
        }

        this.dataTypes = this.getDataTypesFromInputValues(this.internalInputValues );
        this.parseFormData(this.internalInputValues )
    },
    unmounted() {
        this.submitButtonClicked = false;
    },
    watch:{
        formData:{
            handler: function (val) {
                this.$emit('update:modelValue', val)
            },
            deep: true
        }
    },
    methods: {
        
        camelCaseToTitleCase(string) {
            return stringUtil.camelCaseToTitleCase(string);
        },
        getDataTypesFromInputValues(json) {
            const types = {};
            for (let key in json) {
                let type = typeof json[key];
                if (type === 'string' && this.isDateOnly(json[key])) {
                    type = 'date'
                }
                if (type === 'string' && this.isDateTime(json[key])) {
                    type = 'datetime'
                }
                types[key] = type;
            }
            return types;
        },
        parseFormData(formData) {

            for (let key in formData) {
                if (this.dataTypes[key] === 'number') {
                    this.formData[key] = Number(formData[key])
                }
                if (this.dataTypes[key] === 'boolean') {
                    this.formData[key] = Boolean(formData[key])
                }
                if (this.dataTypes[key] === 'datetime') {
                    let parsed = new Date(Date.parse(formData[key]));
                    this.formData[key] = parsed.toISOString().slice(0, 16);
                }
                if (this.dataTypes[key] === 'date') {
                    let parsed = new Date(Date.parse(formData[key]));
                    this.formData[key] = parsed.toISOString().slice(0, 10);
                }
                if (this.dataTypes[key] === 'string') {
                    this.formData[key] = formData[key]
                }
            }
            return this.formData
        },
        inputType(type) {
            switch (type) {
                case "number":
                    return "number";
                case "boolean":
                    return "checkbox";
                case "date":
                    return "date";
                case "datetime":
                    return "datetime-local";
                case "string":
                case "undefined":
                case "null":
                default:
                    return "text";
            }
        },
        isDateTime(datetimeString) {
            const date = parse(datetimeString, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date(datetimeString))
            return isValid(date)
        },
        isDateOnly(dateString){
            const date = parse(dateString, 'yyyy-MM-dd', new Date(dateString))
            return isValid(date)
        },
        submitForm() {
            this.submitButtonClicked = true;
            let formData = { ...this.formData };
            let datetimeKeys = Object.keys(this.dataTypes).filter(key => this.dataTypes[key] === 'datetime')

            for (let i = 0; i < datetimeKeys.length; i++) {
                formData[datetimeKeys[i]] += ":00";
            }
            //need to fix datetime format
            this.$emit('submit', formData)
        },
    },
};
</script>
  