<template>
    <div class="centered-container">
        <i class="gg-danger"></i>
        <h3>This page isn't available... </h3>
    </div>
</template>

<style scoped>
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/danger.css');

.centered-container {
    display: flex;
    flex-direction: column; /* Align children vertically */
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #0a9fd1;
}

.gg-danger {
    transform: scale(2); /* Double the icon size */
    margin-bottom: 20px; /* Add some space between the icon and the text */
}

h3 {
    color: inherit;
}
</style>
