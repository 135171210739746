<template>
  <div class="container-fluid row">
    <div v-if="modalShowned" class="modal d-block mt-5" id="createQualifiers" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <template v-if="selectedRow.id">Edit</template>
              <template v-else>Add</template>
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modalShowned = false"></button>
          </div>
          <div class="modal-body">
            <FormComponent :showSubmitButton="false" ref="form" :inputValues="selectedRow" v-model="selectedRow">
              <template v-slot:formInput="{ formData, inputName }">
                <div v-if="inputName === 'type'" class="mb-5 px-1 w-100 d-flex">
                  <strong><label class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2">Type</label></strong>
                  <select class="form-select" style="width: 150px;" v-model="selectedRow.Type">
                    <option default :value="null">Select Type</option>
                    <option v-for="typeCmt in types" :key="typeCmt.id" :value="typeCmt.typeComment">
                      {{ typeCmt.typeComment }}
                    </option>
                  </select>
                </div>

                <div v-if="inputName == 'chargePer1000Pieces'" class="mb-5 px-1 w-100 d-flex">
                  <strong><label class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2">Charge per 1000 Pieces :</label></strong>
                  <div class="w-100 px-3 py-2 border border-blue-400 focus:outline-0 rounded-lg h-12 dark:bg-gray-800 dark:text-gray-300">
                    {{ calculateTSSChargePerThousand }}</div>
                </div>

                <div v-if="inputName == 'payPer1000Pieces'" class="mb-5 px-1 w-100 d-flex">
                  <label class="block text-base font-medium text-[#07074D] dark:text-gray-300 mx-2"><strong>Pay per 1000 Pieces :</strong></label>
                  <div class="w-100 px-3 py-2 border border-blue-400 focus:outline-0 rounded-lg h-12 dark:bg-gray-800 dark:text-gray-300">
                    {{ calculateInhousePayPerThousand }}</div>
                </div>

              </template>
            </FormComponent>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" id="cuBtn" data-bs-dismiss="modal" class="btn btn-primary" @click="addOrUpdateQualifier(selectedRow)">
              <template v-if="selectedRow.id">Update</template>
              <template v-else>Create</template>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--YEARLY RATES MODEL-->
    <div v-if="ratesModalShown" class="modal d-block mt-5" id="inputRates" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">
              <template>Add</template>
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="ratesModalShown = false"></button>
          </div>
          <div class="modal-body">
            <FormComponent :showSubmitButton="false" ref="form" :inputValues="rateRows" v-model="rateRows">
            </FormComponent>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" id="cuBtn" data-bs-dismiss="modal" class="btn btn-primary" @click="addYearlyRates(rateRows)">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <ABTable class="px-2" ref="qualifiersTable" :isLoading="isLoading" @onFetchRemoteData="fetchRemoteData" :table-data="rows" :page-title="pageTitle" :router-url="routerUrl" :filters="filters" :columns="columns" :total-count="totalCount" :hasDetailsPage="true" @onDeleteRow="deleteRow">
      <template v-slot:createButton>
        <button id="export" class="upload-btn my-3 mx-1" @click="exportToCSV">Export CSV</button>
        <button id="yrRatebtn" type="button" class="my-3 mx-1" @click="ratesModalShown = true">Yearly Rates</button>
        <button id="newbtn" type="button" class="upload-btn my-3 mx-1" @click="showModal()">New</button>
      </template>
      <template v-slot:actions="{ row }">
        <div class="flex items-center justify-center w-full h-full">
          <button @click="showAlert(row.id)" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
            <i class="gg-trash"></i>
          </button>
          <button @click="showModal(row)" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
            <i class="bi bi-pencil"></i>
          </button>
        </div>
      </template>
    </ABTable>
  </div>
</template>

<script>
const defaultQualifierFields = {
  responsiblePerson: "",
  dateOfIssue: new Date().toISOString().substr(0, 10),
  partNumber: "",
  type: "",
  tssCode: "",
  multiplePerson: false,
  tssPiecesPerHour: 0,
  inHousePiecesPerhour: 0,
  chargePer1000Pieces: 0,
  payPer1000Pieces: 0,
  chargePer1000PiecesDelay: 0,
  payPer1000PiecesDelay: 0,
  chargePer1000PiecesExtraDelay: 0,
  payPer1000PiecesExtraDelay: 0,
};

const yearlyRates = {
  tssRates: 0,
  inHouse: 0,
};

import { useToast } from "vue-toast-notification";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { token } from "./token.js";
import ABTable from '../components/ABTable.vue'
import { useQualifierStore } from '@/stores/qualifiers.js'
import { useEmployeeStore } from '@/stores/employees.js'
import { useYearlyRatesStore } from '@/stores/yearlyRates.js'
import { useQualifiersTypeStore } from '@/stores/qualifiersType.js'
import FormComponent from '@/components/FormComponent.vue'

const $toast = useToast();

export default {
  components: {
    ABTable,
    FormComponent
  },
  computed: {},
  data() {
    return {
      rateRows: { ...yearlyRates },
      selectedRow: { ...defaultQualifierFields },
      modalShowned: false,
      ratesModalShown: false,
      ids: [],
      rows: [],
      types: [],
      isLoading: false,
      yearlyRate: null,
      searchQuery: "",
      page: 1,
      pageSize: 3,
      pageSizes: [3, 10, 20, 100],
      totalCount: 0,
      sortBy: "Id",
      sortDirection: "asc",
      pageTitle: 'Qualifiers',
      columns: [
        { label: "Id", field: "id", type: "number", width: "5%" },
        { label: "Responsible Person", field: "responsiblePerson", type: "string" },
        { label: "Date of Issue", field: "dateOfIssue", type: "DateTime" },
        { label: "Part Number", field: "partNumber", type: "string" },
        { label: "Type", field: "type", type: "string" },
        { label: "Method", field: "tssCode", type: "string" },
        { label: "TSSPieces/Hour", field: "tssPiecesPerHour", type: "number" },
        { label: "InHousePieces/Hour", field: "inHousePiecesPerhour", type: "number" },
        { label: "Charge/1000 Pieces", field: "chargePer1000Pieces", type: "number" },
        { label: "Pay/1000 Pieces ", field: "payPer1000Pieces" },
        { label: "Charge/1000 Pieces Delay", field: "chargePer1000PiecesDelay", type: "number" },
        { label: "Pay/1000 Pieces Delay", field: "payPer1000PiecesDelay", type: "number" },
        { label: "Charge/1000Pieces Extra Delay", field: "chargePer1000PiecesExtraDelay", type: "number" },
        { label: "Pay/1000 Pieces Extra Delay", field: "payPer1000PiecesExtraDelay", type: "number" },
      ],
    };
  },
  mounted: function () {
    this.fetchEmployee();
    this.getYearlyRate();
    this.fetchQualifierTypes();
  },
  computed: {
    calculateTSSChargePerThousand() {
      const tssPiecesPerHour = this.selectedRow.tssPiecesPerHour;
      let isBeltByTwo

      if (this.selectedRow.multiplePerson == true) {
        isBeltByTwo = true;
      } else {
        isBeltByTwo = false;
      }

      if (this.yearlyRate != null && this.yearlyRate.tssRates) {
        console.log(tssPiecesPerHour)
        console.log(this.yearlyRate.tssRates)
        let tssRatePerThousand = ((this.yearlyRate.tssRates * 1000) / tssPiecesPerHour).toFixed(2);
        return isBeltByTwo
          ? ((this.yearlyRate.tssRates * 2) * 1000 / tssPiecesPerHour).toFixed(2)
          : tssRatePerThousand;
      }
    },
    calculateInhousePayPerThousand() {
      const inhousePiecesPerHour = this.selectedRow.inHousePiecesPerhour
      let isBeltByTwo

      if (this.selectedRow.multiplePerson == true) {
        isBeltByTwo = true;
      } else {
        isBeltByTwo = false;
      }

      if (this.yearlyRate != null && this.yearlyRate.inHouse) {
        let finalRate = ((this.yearlyRate.inHouse * 1000) / inhousePiecesPerHour).toFixed(2)
        return isBeltByTwo
          ? ((this.yearlyRate.inHouse * 2) * 1000 / inhousePiecesPerHour).toFixed(2)
          : finalRate;
      }
    }
  },
  methods: {
    async fetchQualifierTypes() {
      const employeeStore = useQualifiersTypeStore()
      await employeeStore
        .fetchQualifierType()
        .then(async (res) => {
          if (res.ok) {
            this.types = await res.json();
          } else $toast.error("Error fetching Qualifier Types");
        })
        .catch((err) => {
          $toast.error("Error fetching Qualifier Types") + err;
        });
    },
    async getYearlyRate() {
      const yearlyRatesStore = useYearlyRatesStore()
      const response = await yearlyRatesStore.fetchRates()
      const data = await response.json()
      this.yearlyRate = data
    },
    showModal(row) {
      this.selectedRow = row ? { ...row } : { ...defaultQualifierFields }
      this.modalShowned = true
    },
    async addOrUpdateQualifier(formData) {
      const finalFormData = { ...this.selectedRow, ...formData }
      const qualifierStore = useQualifierStore()

      let response;
      if (this.selectedRow.id) {
        finalFormData.chargePer1000Pieces = this.calculateTSSChargePerThousand,
        finalFormData.payPer1000Pieces = this.calculateInhousePayPerThousand,
        //finalFormData.Type = this.selectedRow.Type[0]
        response = await qualifierStore.updateQualifier(finalFormData)
      } else {
        finalFormData.chargePer1000Pieces = this.calculateTSSChargePerThousand,
        finalFormData.payPer1000Pieces = this.calculateInhousePayPerThousand,
        finalFormData.type = this.selectedRow.Type[0]
        console.log(this.selectedRow.Type[0])
        if(this.selectedRow.Type[0] == 'D'){
          
          this.selectedRow.chargePer1000PiecesDelay = 0.44
          console.log(this.selectedRow.chargePer1000PiecesDelay)
         finalFormData.payPer1000PiecesDelay = 0.44
        }
        response = await qualifierStore.createQualifier(finalFormData)
      }
      if (response.ok) {
        $toast.success(this.selectedRow.id ? `Edited` : `Created`);
        this.modalShowned = false
        this.$refs.qualifiersTable.reload()
      } else {
        var err = await response.text()
        $toast.error(this.selectedRow.id ? `Error editing` : `Error creating`);
      }
    },
    async addYearlyRates(formData) {
      const finalFormData = { ...yearlyRates, ...formData }
      const YearlyRatesStore = useYearlyRatesStore()
      let response;
      response = await YearlyRatesStore.createYearlyRate(finalFormData)
      if (response.ok) {
        $toast.success(`Created`);
        this.modalShowned = false
        this.$refs.qualifiersTable.reload()
      } else {
        var err = await response.text()
        $toast.error(`Error creating`);
      }
    },
    async fetchRemoteData(remoteParams) {
      const qualifierStore = useQualifierStore()
      const dataResponse = await qualifierStore
        .searchQualifiers(remoteParams.page, remoteParams.pageSize, remoteParams.sortBy, remoteParams.sortDirection, remoteParams.searchQuery)
      this.rows = await dataResponse.items
      this.totalCount = await dataResponse.totalCount
      this.isLoading = false
    },
    async fetchEmployee() {
      const employeeStore = useEmployeeStore()
      await employeeStore
        .fetchEmployees()
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else $toast.error("Error fetching Employees");
        })
        .then((data) => (this.users = data))
        .catch((err) => {
          $toast.error("Error fetching Employees") + err;
        });
    },
    showAlert(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.remove(id);
          }
        });
    },
    async remove(id) {
      const qualifierStore = useQualifierStore()
      const dataResponse = await qualifierStore
        .deleteQualifier(id)
      this.$refs.qualifiersTable.reload()
    },
    exportToCSV() {
      const csvHeader = this.columns.map((column) => column.label).join(",");
      const csvData = this.rows.map((row) =>
        this.columns.map((column) => row[column.field]).join(",")
      );
      const csvContent = [csvHeader, ...csvData].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "qualifiers.csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style>
.upload-btn {
  float: right;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

#cuBtn {
  background-color: #008cba;
  color: white;
}

#newbtn {
  float: right;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button .upload-btn:hover {
  background-color: #388e3c;
}

#yrRatebtn {
  float: right;
  background-color: hsl(246, 93%, 45%);
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

#export {
  float: right;
  background-color: hsl(9, 90%, 51%);
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}
</style>
