import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'
import { el } from 'date-fns/locale';

export const useGoodsStore = defineStore({
  id: 'good',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchGoods() {
      const Goods = await storeUtils.fetch('BagInventory/GetUploadedBags')
      return Goods;
    },
    async fetchRetunedGoods() {
      const Goods = await storeUtils.fetch('BagInventory/GetReturnedBags')
      return Goods;
    },

    async searchRetunedGoods(page, pageSize, sortBy, sortDirection, searchQuery) {
      const Goods = await storeUtils.search('BagInventory/GetPagedReturnedBags', page, pageSize, sortBy, sortDirection, searchQuery)
      return Goods
    },
    async searchGoods(page, pageSize, sortBy, sortDirection, searchQuery) {
      const Goods = await storeUtils.search('BagInventory/GetPagedBags', page, pageSize, sortBy, sortDirection, searchQuery)
      return Goods
    },

    async fetchGood(id) {
      const Good = await storeUtils.fetchOne('BagInventory/GetUploadedBags', id)
      return Good;
    },
 
    async updateGoods(Goods, id = null) {
      let updatedGoods;
      if(id){
        updatedGoods = await storeUtils.update(`BagInventory/${id}`, Goods)
      } else {
        updatedGoods = await storeUtils.update('BagInventory/UpdateBagQuantities', Goods)
      }
      return updatedGoods;
    },
    
    async deleteGood(id) {
      const deletedGood = await storeUtils.delete('BagInventory/Delete', id)
      return deletedGood;
    },
  },
})