<script setup>
import { RouterLink, RouterView } from 'vue-router'
</script>
<script>

export default {
  data(){
    return {
      toggleMenu: true
    }
  },
  methods: {
    logOut() {
      this.toggleMenu = false
      Cookie.remove("authToken")
      Cookie.remove("loggedInUser")
      this.$router.replace("/login")
      this.toggleMenu = true
      window.location.reload()
    }
  }
}
</script>
<template>
  <component :is="$route.meta.layout">
    <RouterView />
  </component>
</template>

<style scoped></style>