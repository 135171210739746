import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useAuditStore = defineStore({
  id: 'audit',
  endpoint: 'Audit',
  state: () => ({
    // products: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchPagedAudit(page, pageSize, sortBy, sortDirection) {
      const audits = await storeUtils.fetchPaged('Audit', page, pageSize, sortBy, sortDirection)
      return audits;
    },
    async searchLogs(page, pageSize, sortBy, sortDirection, searchQuery) {
      const Logs = await storeUtils.search('Audit', page, pageSize, sortBy, sortDirection, searchQuery)
      return Logs
    },
  },
})