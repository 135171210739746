import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useBagInventoryStore = defineStore({
  id: 'bagInventory',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    
    async searchBagInventory(page, pageSize, sortBy, sortDirection, searchQuery) {
      const BagInventory = await storeUtils.search('BagInventory', page, pageSize, sortBy, sortDirection, searchQuery)
      return BagInventory
    },

    async searchBagInventoryWhatsInStock(page, pageSize, sortBy, sortDirection, searchQuery) {
      const BagInventory = await storeUtils.search('BagInventory/GetWhatIsInStockPaged', page, pageSize, sortBy, sortDirection, searchQuery)
      return BagInventory
    },

    async fetchUploadedBagInventory() {
      const UploadedBagInventory = await storeUtils.fetchOne('BagInventory/GetUploadedBags')
      return UploadedBagInventory;
    },

    async fetchBagInventory(id) {
      const BagInventory = await storeUtils.fetchOne('BagInventory/', id)
      return BagInventory;
    },

    async createBagInventory(bagInventory) {
      const BagInventory = await storeUtils.create(`BagInventory`, bagInventory)
      return BagInventory;
    },
    
 
    async updateBagInventory(bagInventory) {
      const BagInventory = await storeUtils.update(`BagInventory`, bagInventory)
      return BagInventory;
    },

    async assignEmployeeBagInventory(data) {
      const BagInventory = await storeUtils.update(`BagInventory/AssignEmployee`, data)
      return BagInventory;
    },

    async assignBx2EmployeeBagInventory(data) {
      const BagInventory = await storeUtils.update(`BagInventory/AssignBx2Employee`, data)
      return BagInventory;
    },
    
    async deleteBagInventory(id) {
      const BagInventory = await storeUtils.delete('BagInventory', id)
      return BagInventory;
    },

  },
})