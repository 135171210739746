<template>
     <div>
    <menu-bar></menu-bar>
  </div>
  </template>
  
<script>

import axios from "axios";
import { useToast } from "vue-toast-notification";

export const apiInstance = axios.create({
  
  responseType: "json",
  headers: {
    //'Content-Type': 'application/json',
    // you dont need to pre-define all headers
  },
});

const $toast = useToast();

  export default {
    name: 'MenuBar',
    // Component logic and methods go here
 
  methods: {
    refreshData() {
      fetch(`${this.apiPrefix}/BagInventory/GetUploadedBags`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else $toast.error("Error fetching Data");
        })
        .then((data) => (this.rows = data))
        .catch((err) => {
          $toast.error("Error fetching data") + err;
        });

      fetch(`${this.apiPrefix}/Employee`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => (this.users = data))
        .catch((err) => {
          $toast.error("Error fetching Employees") + err;
        });
    },
  }
};
  
      
  </script>
  
  <style scoped>
  @import url("https://unpkg.com/css.gg@2.0.0/icons/css/trash.css");
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
  </style>
  