<template>
  <div class="mx-5">
    <date-picker v-model="startDate" format="MM / dd / yyyy" placeholder="Start Date" :class="custom - date - picker " >
    <template v-slot:icon-calendar></template></date-picker>
    <date-picker v-model="finalDate" format="MM / dd / yyyy" placeholder="End Date" :class="custom - date - picker" >
    <template v-slot:icon-calendar> </template></date-picker>
    <div style="float: right;">
      <button style="background-color: blue;" class="my-4 mx-3" @click="sendData">Generate Salary Excel</button>
      <button style="background-color:green" class="my-4 mx-3" @click="sendTSSData">Generate TSS Charges Excel</button>
    </div>
    <a ref="downloadLink" style="display: none"></a>
  </div>
</template>


  
<script>
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { token } from "./token.js";
import { storeUtils } from '../utils/store.js'
import { useQualifierStore } from '@/stores/qualifiers.js'
import axios from "axios";
import { useToast } from "vue-toast-notification";
export const apiInstance = axios.create({

  responseType: "json",
  headers: {
    //'Content-Type': 'application/json',
    // you dont need to pre-define all headers
  },
});


const $toast = useToast();
apiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      startDate: null,
      finalDate: null,
      storeUtils
    };
  },
  methods: {

   /* async sendData() {
      const qualifierStore = useQualifierStore()
      const formattedStartDate = this.formatDate(this.startDate);
      const formattedFinalDate = this.formatDate(this.finalDate);

      const response = await qualifierStore
        .getSalary(formattedStartDate, formattedFinalDate)
          
      if(response.ok){
        this.$refs.downloadLink.setAttribute("href", response);
        $toast.success(`Download succesfully`);
        this.$refs.downloadLink.click();
      }
      else {
        console.error("Dates have not been selected.");
      }
    },*/

    sendData() {
      const formattedStartDate = this.formatDate(this.startDate);
      const formattedFinalDate = this.formatDate(this.finalDate);
      console.log(formattedFinalDate);
      if (this.startDate && this.finalDate) {
        const url = `${this.apiPrefix}/Qualifiers/EmployeeWageTable?startDate=${formattedStartDate}&finalDate=${formattedFinalDate}`;

        apiInstance
          .get(url)
          .then((result) => {
            if (result.status >= 200 && result.status <= 205) {
              $toast.success(`Download succesfully`);
              const downloadUrl = `${this.apiPrefix}/Qualifiers/EmployeeWageTable?startDate=${formattedStartDate}&finalDate=${formattedFinalDate}`;

              // Setăm atributul href al link-ului ascuns la URL-ul de descărcare
              this.$refs.downloadLink.setAttribute("href", downloadUrl);

              // Simulați clicul pe link-ul ascuns pentru a începe descărcarea
              this.$refs.downloadLink.click();
            }
          })
          .catch((err) => $toast.error("Error") + err);
      } else {
        console.error("Dates have not been selected.");
      }
    },
    sendTSSData() {
      const formattedStartDate = this.formatDate(this.startDate);
      const formattedFinalDate = this.formatDate(this.finalDate);
      console.log(formattedFinalDate);
      if (this.startDate && this.finalDate) {
        const url = `${this.apiPrefix}/Qualifiers/TSSTable?startDate=${formattedStartDate}&finalDate=${formattedFinalDate}`;

        apiInstance
          .get(url)
          .then((result) => {
            if (result.status >= 200 && result.status <= 205) {
              $toast.success(`Download succesfully`);
              const downloadUrl = `${this.apiPrefix}/Qualifiers/TSSTable?startDate=${formattedStartDate}&finalDate=${formattedFinalDate}`;

              // Setăm atributul href al link-ului ascuns la URL-ul de descărcare
              this.$refs.downloadLink.setAttribute("href", downloadUrl);

              // Simulați clicul pe link-ul ascuns pentru a începe descărcarea
              this.$refs.downloadLink.click();
            }
          })
          .catch((err) => $toast.error("Error") + err);
      } else {
        console.error("Dates have not been selected.");
      }
    },
    
    formatDate(date) {
      if (!date) return "";
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const yyyy = date.getFullYear();
      return mm + "/" + dd + "/" + yyyy;
    },
  },
};
</script>
<style scoped>
.custom-date-picker {
  width: 50px; /* Ajustează lățimea la nevoile tale */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none; /* Elimină conturul la focus */
  cursor: pointer; /* Indicator de cursor la hover */
}

/* Stil pentru buton */
button {
  padding: 10px 20px;
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Stil pentru buton la hover */
button:hover {
  background-color: #007aa9;
}
</style>
  