import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useFileStore = defineStore("files", {
  state: () => ({
    //fileManagerModal: false,
  }),

  actions: {
    async uploadFile(file){
      const response = await storeUtils.upload("BagInventory/UploadBag", file)
      return response
    },
  },
  
});