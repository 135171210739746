<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col d-flex justify-content-end">
        <input type="text" v-model="searchQuery" @input="handleSearch" placeholder="Search..." class="form-control" style="max-width: 200px; margin-right: 1%;">
        <div v-if="showGenerateReportButton">
          <button class="btn btn-primary report" @click="deliverNo">
            Generate Delivery Report
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Select</th>
              <th v-for="column in columns" :key="column.field">{{ column.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in paginatedRows" :key="row.id">
              <td>
                <input type="checkbox" :checked="isRowSelected(row)" @click="toggleRowSelection(row)" />
              </td>
              <td v-for="column in columns" :key="column.field">{{ row[column.field] }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="isLoading" class="text-center">
          <span>Loading...</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <div>
          <label for="pageSize">Rows per page:</label>
          <select v-model="pageSize" id="pageSize" @change="changePageSize" class="form-control d-inline-block" style="width: auto;">
            <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
          </select>
        </div>
        <div>
          <button @click="prevPage" :disabled="page === 1" class="btn btn-secondary mr-2">Previous</button>
          <span>Page {{ page }} of {{ totalPages }}</span>
          <button @click="nextPage" :disabled="page === totalPages" class="btn btn-secondary ml-2">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toast-notification";
import { token } from "./token.js";
import axios from "axios";
import { useDeliveryNotesStore } from '@/stores/deliveryNote.js';
import { useDeliveryNotePrintedStore } from '@/stores/deliveryNotePrinted.js';

const $toast = useToast();

export const apiInstance = axios.create({
  responseType: "json",
  headers: {
    //'Content-Type': 'application/json',
    // you dont need to pre-define all headers
  },
});
apiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default {
  name: 'Delivery Report',
  data() {
    return {
      ids: [],
      isSearch: false,
      isLoading: false,
      remoteParams: {},
      _selectionIds: [],
      _projectCodes: [],
      rows: [],
      codes: [],
      users: [],
      searchQuery: "",
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalCount: 0,
      sortBy: "Id",
      sortDirection: "asc",
      pageTitle: 'Delivery Report',
      columns: [
        { label: "Id", field: "id", type: "number", width: "5%", sortable: false },
        { label: "Bag Number", field: "bagNumber", sortable: false },
        { label: "Part Number", field: "partNumber", sortable: false },
        { label: "Inspection Type", field: "inspectionType" },
        { label: "Quality", field: "quality" },
        { label: "Quantity Issued", field: "quantityIssued", type: "number" },
        { label: "Quantity Passed", field: "quantityPassed", type: "number" },
        { label: "Quantity Reject", field: "quantityFailed", type: "number" },
        { label: "Notes", field: "notes" },
      ],
      selectedRows: [],
      showGenerateReportButton: false,
    };
  },
  computed: {
    showGenerateReportButton() {
      return this.selectedRows.length > 0;
    },
    canPrint() {
      return this._selectionIds.value.length > 0 && this._projectCodes.value.length <= 1;
    },
    filteredRows() {
      if (!this.searchQuery) {
        return this.rows;
      }
      const searchQueryLower = this.searchQuery.toLowerCase();
      return this.rows.filter(row =>
        this.columns.some(column =>
          String(row[column.field]).toLowerCase().includes(searchQueryLower)
        )
      );
    },
    paginatedRows() {
      const start = (this.page - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredRows.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredRows.length / this.pageSize);
    }
  },
  created() {
    this.fetchRemoteData();
  },
  methods: {
    async fetchRemoteData() {
      this.isLoading = true;
      const goodsStore = new useDeliveryNotesStore();
      const response = await goodsStore.fetchDeliveryNotes();
      const data = await response.json();
      this.rows = data;
      this.isLoading = false;
    },
    handleSearch() {
      this.page = 1; // Reset to first page on search
    },
    changePageSize() {
      this.page = 1; // Reset to first page on page size change
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
      }
    },
    exportToCSV() {
      const csvHeader = this.columns.map((column) => column.label).join(",");
      const csvData = this.filteredRows.map((row) =>
        this.columns.map((column) => row[column.field]).join(",")
      );
      const csvContent = [csvHeader, ...csvData].join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Delivery_Report.csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleRowSelect(data) {
      console.log("selectedRows", data.selectedRows);
      this.selectedRows = data.selectedRows;
    },
    async deliverNo() {
      console.log("selectedRows", this.selectedRows);
      const ids = this.selectedRows.map(row => row.id);
      const bagNumbers = this.selectedRows.map(row => row.bagNumber);
      const deliveryNoteStore = useDeliveryNotePrintedStore();
      const response = await deliveryNoteStore.createDeliveryNotePrinted(ids, bagNumbers);
      if (response.ok) {
        
        this.$router.push({ name: 'toDeliver', params: { ids: ids.join(',') } });
      }
    },
    refreshData() {
      if (this.$router && this.$router.params && this.$router.params.id) {
        this.id = this.$router.params.id;
        fetch(`${this.apiPrefix}/DeliveryNote/Valid`).then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Error fetching Data");
          }
        }).then((data) => {
          this.rows = data;
        }).catch((err) => {
          $toast.error("Error fetching data: " + err);
        });
      } else {
        $toast.error("Invalid URL: 'id' parameter is missing.");
      }
    },
    toggleRowSelection(row) {
      const index = this.selectedRows.findIndex((selectedRow) => selectedRow.id === row.id);
      if (index !== -1) {
        this.selectedRows.splice(index, 1);
      } else {
        this.selectedRows.push(row);
      }
    },
    isRowSelected(row) {
      return this.selectedRows.some((selectedRow) => selectedRow.id === row.id);
    },
  },
};
</script>

<style scoped>
@import url("https://unpkg.com/css.gg@2.0.0/icons/css/trash.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

.report {
  background: rgb(8, 207, 8);
  color: white;
  text-align: center;
  margin-right: 10%;
}
</style>
