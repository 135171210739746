import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useQualifierStore = defineStore({
  id: 'qualifier',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchQualifiers() {
      const Goods = await storeUtils.fetch('Qualifiers')
      return Goods;
    },
    async searchQualifiers(page, pageSize, sortBy, sortDirection, searchQuery) {
      const Goods = await storeUtils.search('Qualifiers', page, pageSize, sortBy, sortDirection, searchQuery)
      return Goods
    },

    async fetchQualifier(id) {
      const Good = await storeUtils.fetchOne('Qualifiers/', id)
      return Good;
    },

    async createQualifier(Qualifier) {
      const updatedGoods = await storeUtils.create(`Qualifiers`, Qualifier)
      return updatedGoods;
    },
    
 
    async updateQualifier(Qualifier) {
      const updatedGoods = await storeUtils.update(`Qualifiers`, Qualifier)
      return updatedGoods;
    },
    
    async deleteQualifier(id) {
      const deletedGood = await storeUtils.delete('Qualifiers', id)
      return deletedGood;
    },

    async getSalary(startDate, endDate) {
      const wage = await storeUtils.filtered(`EmployeeWageTable/`, {startDate, endDate})
      return wage;
    },

   

  

    
  },
})