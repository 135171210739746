import { defineStore } from 'pinia'
import { storeUtils } from '../utils/store.js'

export const useDeliveryNotePrintedStore = defineStore({
  id: 'DeliveryNotePrinted',
  state: () => ({
    // discounts: [], //avoid using global state if at all possible
  }),
  actions: {
    async fetchDeliveryNotesPrinted() {
      const DeliveryNotePrinted = await storeUtils.fetch('DeliveryNotePrinted')
      return DeliveryNotePrinted;
    },
    async fetchGetLastRecordId() {
      const DeliveryNotePrinted = await storeUtils.fetch('DeliveryNotePrinted/GetLastRecordId')
      return DeliveryNotePrinted;
    },
    
    async searchDeliveryNotePrinted(page, pageSize, sortBy, sortDirection, searchQuery) {
      const DeliveryNotePrinted = await storeUtils.search('DeliveryNotePrinted/GetPaged', page, pageSize, sortBy, sortDirection, searchQuery)
      return DeliveryNotePrinted
    },

    async fetchDeliveryNotePrintedById(id) {
      const DeliveryNotePrinted = await storeUtils.fetchOne('DeliveryNotePrinted/', id)
      return DeliveryNotePrinted;
    },

    async createDeliveryNotePrinted(ids, bagNumbers) {
      const DeliveryNotePrinted = await storeUtils.create(`DeliveryNotePrinted/CreateDeliveryNotePrinted`, {deliveryNoteIds:ids, bagNumbers:bagNumbers})
      return DeliveryNotePrinted;
    },
    
 
    async updateDeliveryNotePrinted(deliveryNotePrinted) {
      const DeliveryNotePrinted = await storeUtils.update(`DeliveryNotePrinted`, deliveryNotePrinted)
      return DeliveryNotePrinted;
    },
    
    async deleteDeliveryNotePrinted(id) {
      const DeliveryNotePrinted = await storeUtils.delete('PastDelivery', id)
      return DeliveryNotePrinted;
    },

    async fetchDeliveryIdPrintedByDeliveryId(bagId) {
      const DeliveryNotes = await storeUtils.fetch(`DeliveryNotePrinted/GetDeliveryIdByBagNo?${bagId}`)
      return DeliveryNotes;
    },
  },
});
