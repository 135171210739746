<template>
  <main class="form-signin w-100 m-auto">
    <form @submit.prevent="submitForm">
      <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

      <div class="form-floating">
        <input type="text" class="form-control" id="floatingInput" placeholder="johndoe" v-model="formData.username">
        <label for="floatingInput">Username</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" id="floatingPassword" placeholder="Password" v-model="formData.password">
        <label for="floatingPassword">Password</label>
      </div>

      <!-- <div class="form-check text-start my-3">
        <input class="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault">
        <label class="form-check-label" for="flexCheckDefault">
          Remember me
        </label>
      </div> -->
      <button class="btn btn-primary w-100 py-2" type="submit">Sign in</button>
    </form>
  </main>
</template>
<script>
import { useAuthStore } from '@/stores/auth'
import Cookie from 'js-cookie'
export default{
    data(){
        return{
          formError: false,
          formData: {}
        }
    },
    methods: {
        async submitForm() {
          this.formError = false
            const authStore = useAuthStore()
            const response = await authStore.login(this.formData)
            if(response.ok){
              const data = await response.json()
              console.log(data)
              Cookie.set('authToken', data.token)
              window.location.reload()
            } else {
              this.formError = true
            }
        }
    }
}
</script>