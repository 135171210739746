import Cookie from 'js-cookie'
import { getCurrentInstance } from 'vue';

export const storeUtils = {

    //apiAurl: "https://localhost:7164",
    apiAurl: process.env.NODE_ENV == 'development' ? 'http://localhost:7164' : '/api',
    getLoggedInUser: function () {
        if(!Cookie.get('loggedInUser')) return null;
        return JSON.parse(Cookie.get('loggedInUser'))
    },
    getToken: function () {
        return Cookie.get('authToken')
    },
    fetch: async function (endpoint) {
        let headers = {
            'Access-Control-Allow-Origin': '*', // replace '*' with your domain name
        };
        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }
        return await fetch(`${this.apiAurl}/${endpoint}`, { headers });
    },
    export: async function (endpoint) {
        window.location.href = `${this.apiAurl}/${endpoint}/export`;
    },
    fetchPaged: async function (endpoint, page, pageSize, sortBy, sortDirection) {
        let headers = {
            'Access-Control-Allow-Origin': '*', // replace '*' with your domain name
        };

        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }

        let queryParams = new URLSearchParams({
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortDirection: sortDirection,
        });


        const url = `${this.apiAurl}/${endpoint}/paged?${queryParams}`;
        const response = await fetch(url, { headers });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error(`Failed to search from ${endpoint}`);
        }
    },
    search: async function (endpoint, page, pageSize, sortBy, sortDirection, searchQuery, type) {
        let headers = {
            'Access-Control-Allow-Origin': '*', // replace '*' with your domain name
        };

        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }

        let queryParams = new URLSearchParams({
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortDirection: sortDirection,
            searchQuery: searchQuery,
            type: type,
        });

        if (type === undefined || type === null || type === "") {
            queryParams.delete('type');
        }

        if (searchQuery === undefined || searchQuery === null || searchQuery === "" || searchQuery.trim().length === 0) {
            queryParams.delete('searchQuery');
        }

        console.log(queryParams)

        const url = `${this.apiAurl}/${endpoint}/search?${queryParams}`;
        const response = await fetch(url, { headers });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error(`Failed to search from ${endpoint}`);
        }
    },
    filtered: async function (endpoint, page, pageSize, sortBy, sortDirection, searchQuery, filters) {
        let headers = {
            'Access-Control-Allow-Origin': '*', // replace '*' with your domain name
        };

        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }

        let queryParams = new URLSearchParams({
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortDirection: sortDirection,
            searchQuery: searchQuery,
            filters: JSON.stringify(filters),
        });

        if (filters === undefined || filters === null || Object.keys(filters).length === 0) {
            queryParams.delete('filters');
        }

        if (searchQuery === undefined || searchQuery === null || searchQuery === "") {
            queryParams.delete('searchQuery');
        }

        const url = `${this.apiAurl}/${endpoint}/filtered?${queryParams}`;
        const response = await fetch(url, { headers });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error(`Failed to search from ${endpoint}`);
        }
    },
    count: async function (endpoint, searchQuery, filters) {
        let headers = {
            'Access-Control-Allow-Origin': '*', // replace '*' with your domain name
        };

        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }

        let queryParams = new URLSearchParams({
            searchQuery: searchQuery,
            filters: JSON.stringify(filters),
        });

        if (filters === undefined || filters === null || Object.keys(filters).length === 0) {
            queryParams.delete('filters');
        }

        if (searchQuery === undefined || searchQuery === null || searchQuery === "") {
            queryParams.delete('searchQuery');
        }

        const url = `${this.apiAurl}/${endpoint}/count?${queryParams}`;
        const response = await fetch(url, { headers });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error(`Failed to count from ${endpoint}`);
        }
    },
    fetchOne: async function (endpoint, id) {
        let headers = {
            'Access-Control-Allow-Origin': '*', // replace '*' with your domain name
        };
        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }
        const response = await fetch(`${this.apiAurl}/${endpoint}/${id}`, { headers })
        if (response.ok) {
            const data = await response.json()
            return data
        } else {
            console.error(`Failed to fetch from ${endpoint} with id ${id}`)
        }
    },
    create: async function (endpoint, datum) {
        let headers = {
            'Content-Type': 'application/json'
        }
        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }
        return await fetch(`${this.apiAurl}/${endpoint}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(datum),
        })
    },
    update: async function (endpoint, datum) {
        let headers = {
            'Content-Type': 'application/json'
        }
        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }
        return await fetch(`${this.apiAurl}/${endpoint}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(datum),
        })
    }, 
    delete: async function (endpoint, id) {
        let headers = {
            'Content-Type': 'application/json'
        }
        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }
        return await fetch(`${this.apiAurl}/${endpoint}/${id}`, {
            method: 'DELETE',
            headers: headers,
        })
    },
    upload: async function (endpoint, file, name, entityName, entityType, fileTypeId) {
        let formData = new FormData();
        formData.append("formFile", file);
        formData.append("name", name);
        formData.append("entityName", entityName);
        formData.append("entityType", entityType);
        formData.append("fileTypeId", fileTypeId);

        let headers = {
            // 'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        }
        if(this.getToken()){
            headers['Authorization'] = `Bearer ${this.getToken()}`
        }
        return await fetch(`${this.apiAurl}/${endpoint}`, {
            method: 'POST',
            headers: headers,
            body: formData,
        })
    }
}