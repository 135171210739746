<template>
  <div class="container-fluid row">
    <div v-if="modalShowned" class="modal d-block mt-5" id="createEmployee" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5"> 
              <template v-if="selectedRow.id">Edit</template>
              <template v-else>Add</template></h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modalShowned = false"></button>
          </div>
          <div class="modal-body">
            <FormComponent :showSubmitButton="false" ref="form" :inputValues="selectedRow" @submit="addOrUpdateEmployee">
            </FormComponent>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" data-bs-dismiss="modal" class="btn btn-primary" @click="$refs.form.submitForm()">
              <template v-if="selectedRow.id">Update</template>
              <template v-else>Create</template>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ABTable
      class="px-2"
      ref="employeeTable"
      :isLoading="isLoading"
      @onFetchRemoteData="fetchRemoteData"
      :table-data="rows"
      :page-title="pageTitle"
      :router-url="routerUrl"
      :filters="filters"
      :columns="columns"
      :total-count="totalCount"
      :page-size="pageSize"
      :hasDetailsPage="true"
      @onDeleteRow="deleteRow">
      <template v-slot:createButton>
        <button id="newbtn" type="button" class="upload-btn my-3 mx-3" @click="modalShowned = true">New Employee</button>
      </template>
      <template v-slot:actions="{ row }">
        <div class="flex items-center justify-center w-full h-full">
          <button @click="showAlert(row.id)" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
            <i class="gg-trash"></i>
          </button>
          <button @click="selectedRow = row; modalShowned = true" class="flex items-center px-3 py-1 space-x-3 transition-colors duration-300 transform border rounded-lg border-blue-400 focus:outline-none mx-1 dark:bg-gray-800 dark:text-gray-300">
            <i class="bi bi-pencil"></i>
          </button>
        </div>
      </template>
    </ABTable>
  </div>
</template>

<script>
const defaultEmployeeFields = {
  name: "",
  surname: "",
  punchCode: "",
  // you can add more fields here
};

import ABTable from '../components/ABTable.vue';
import FormComponent from '@/components/FormComponent.vue';
import { useEmployeeStore } from '@/stores/employees.js';
import { token } from "./token.js";
import { useToast } from "vue-toast-notification";

const $toast = useToast();

export default {
  name: "EmployeeTable",
  components: {
    ABTable,
    FormComponent
  },
  data() {
    return {
      isLoading: false,
      remoteParams: {},
      searchQuery: "",
      page: 1,
      pageSize: 100, // Default number of rows displayed
      pageSizes: [3, 10, 20, 100],
      totalCount: 0,
      sortBy: "Id",
      sortDirection: "asc",
      pageTitle: 'Employees',
      selected: null,
      options: ["test1", "test2", "test3"],
      name: "",
      surname: "",
      selectedRow: { ...defaultEmployeeFields },
      modalShowned: false,
      columns: [
        { label: "Id", field: "id", type: "number", width: "5%" },
        { label: "Name", field: "name", width: "15%" },
        { label: "Surname", field: "surname", width: "15%" },
        { label: "Punch Code", field: "punchCode", width: "15%" },
        // {label: "", field: "edit", sortable: false,width: "8%",},
      ],
      rows: [],
    };
  },
  methods: {
    disablebag() {
      return true;
    },

    async fetchRemoteData(remoteParams) {
      this.remoteParams = remoteParams;
      this.isLoading = true;
      const employeeStore = useEmployeeStore();
      const dataResponse = await employeeStore.searchEmployees(
        remoteParams.page,
        remoteParams.pageSize,
        remoteParams.sortBy,
        remoteParams.sortDirection,
        remoteParams.searchQuery
      );
      this.rows = await dataResponse.items;
      this.totalCount = await dataResponse.totalCount;
      this.isLoading = false;
    },

    async fetchEmployee() {
      const employeeStore = useEmployeeStore();
      await employeeStore
        .fetchEmployees()
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else $toast.error("Error fetching Employees");
        })
        .then((data) => (this.users = data))
        .catch((err) => {
          $toast.error("Error fetching Employees" + err);
        });
    },

    showAlert(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.remove(id);
          }
        });
    },

    async remove(id) {
      const employeeStore = useEmployeeStore();
      await employeeStore
        .deleteEmployee(id)
        .then((result) => {
          if (result.status >= 200 && result.status <= 205) {
            $toast.success(`Employee ${id} deleted`);
            this.fetchRemoteData(this.remoteParams);
          }
        })
        .catch((err) => $toast.error("Error deleting Employee " + err));
    },

    async editUser(id, name, surname) {
      const employeeStore = useEmployeeStore();
      await employeeStore
        .updateEmployee(id, name, surname)
        .then((result) => {
          if (result.status >= 200 && result.status <= 205) {
            this.$refs.employeeTable.reload();
            $toast.success(`Employee ${this.name} ${this.surname}(${this.punchCode}) edited`);
          }
        })
        .catch((err) => $toast.error("Error editing Employee" + err));
    },

    async addOrUpdateEmployee(formData) {
      console.log(formData);
      const finalFormData = { ...this.selectedRow, ...formData };
      console.log(finalFormData);
      const employeeStore = useEmployeeStore();
      let response;
      if (this.selectedRow.id) {
        response = await employeeStore.updateEmployee(finalFormData);
      } else {
        response = await employeeStore.createEmployee(finalFormData);
      }
      if (response.ok) {
        $toast.success(
          this.selectedRow.id
            ? `Employee ${finalFormData.name} ${finalFormData.surname}${finalFormData.punchCode} Edited`
            : `Employee ${finalFormData.name} ${finalFormData.surname}${finalFormData.punchCode} Created`
        );
        this.modalShowned = false;
        this.fetchRemoteData(this.remoteParams);
      } else {
        var err = await response.text();
        $toast.error(this.selectedRow.id ? `Error Editing Employee` : `Error Creating Employee`);
        console.log(err);
      }
      console.log(response);
    },
  },
};
</script>

<style scoped>
@import url("https://unpkg.com/css.gg@2.0.0/icons/css/trash.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
